import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import store from './Redux/Store';
import { Provider } from 'react-redux'
import axios from 'axios'
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
axios.interceptors.request.use(
  function (request) {
    if (typeof (request.headers['Authorization']) === 'undefined') {
      const token = localStorage.getItem('token')
      request.headers['Authorization'] = token ? 'Bearer ' + token : '';
      // request.headers['Access-Control-Allow-Origin'] ='*';
      // request.headers['Content-Type'] ='application/json';
      //Use below one to accept all content type
      //request.headers['Content-Type'] ='*/*'
      // if (token === null || token === undefined || token === "") {
      //   const tokenLocal = localStorage.getItem('token')
      //   request.headers['Authorization'] = tokenLocal ? 'Bearer ' + tokenLocal : ''
      // }
      // else {
      //   request.headers['Authorization'] = token ? 'Bearer ' + token : ''
      // }
    }
    return request;
  },
  function (error) {
    return Promise.reject(error)
  }
)

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <Suspense fallback={<div>Loading translations...</div>}>
        <App />
      </Suspense>
    </I18nextProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
