import React from "react";
import "./ModifySearchBar.css";
import Grid from "@material-ui/core/Grid";
import months from "../../data/Months";
import Days from "../../data/Days";
import { useTranslation } from "react-i18next";

function ModifySearchBar({
  search,
  setSearch,
  modifySearch,
  handleModifySearch,
  loading,
}) {
  const { t } = useTranslation();
  const getMultidestinationDestinations = () => {
    let destinationString = "";
    destinationString = destinationString.concat(`${search?.from?.city} to `);
    const lastDestination =
      search?.destinations[search?.destinations?.length - 1].to?.city;
    destinationString = destinationString.concat(`${lastDestination}`);
    return destinationString + " ";
  };
  const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
  };

  const getMultidestinationViaDestinations = () => {
    let viaDestinationArray = [];
    viaDestinationArray.push(search?.to?.city);
    for (let [index, destination] of search?.destinations?.entries()) {
      if (index === search?.destinations?.length - 1) {
        viaDestinationArray.push(destination?.from?.city);
      } else {
        viaDestinationArray.push(destination?.from?.city);
        viaDestinationArray.push(destination?.to?.city);
      }
    }
    return (
      <span className='opacity-5'>
        via {viaDestinationArray.filter(onlyUnique).join().replace(/,/g, ", ")}
      </span>
    );
  };
  return (
    <div className='modify-search-bar-container'>
      <Grid container spacing={2} className='height-100'>
        <Grid item xs={10}>
          <Grid container className='height-100'>
            <Grid item xs={2}>
              <div className='search-info-container'>
                <div className='search-info-title'>{t("Trip Type")}</div>
                <div className='search-info-description trip-type-modify-text'>
                  {t(search?.type)}
                </div>
              </div>
            </Grid>
            {search?.type !== "Multidestination" && (
              <Grid item xs={2}>
                <div className='search-info-container'>
                  <div className='search-info-title'>{t('From')}</div>
                  <div className='search-info-description'>
                    {search?.from?.city}, {search?.from?.country}
                  </div>
                </div>
              </Grid>
            )}
            {search?.type !== "Multidestination" && (
              <Grid item xs={2}>
                <div className='search-info-container'>
                  <div className='search-info-title'>{t('To')}</div>
                  <div className='search-info-description'>
                    {search?.to?.city}, {search?.to?.country}
                  </div>
                </div>
              </Grid>
            )}

            {search?.type !== "Multidestination" && (
              <Grid item xs={2}>
                <div className='search-info-container'>
                  <div className='search-info-title'>{t("Depart")}</div>
                  {
                    new Date(search?.depart) && <div className='search-info-description'>
                      {Days[new Date(search?.depart)?.getDay()]},{" "}
                      {months[new Date(search?.depart)?.getMonth()]} {new Date(search?.depart)?.getDate()}
                      , {new Date(search?.depart)?.getFullYear().toString().substring(2, 4)}
                    </div>
                  }

                </div>
              </Grid>
            )}
            {search?.type !== "Multidestination" && (
              <Grid item xs={2}>
                <div className='search-info-container'>
                  <div className='search-info-title'>{t("Return")}</div>
                  {search?.type === "RoundTrip" && new Date(search?.return) && (
                    <div className='search-info-description'>
                      {Days[new Date(search?.return)?.getDay()]},{" "}
                      {months[new Date(search?.return)?.getMonth()]}{" "}
                      {new Date(search?.return)?.getDate()},{" "}
                      {new Date(search?.return)?.getFullYear().toString().substring(2, 4)}
                    </div>
                  )}
                  {search?.type === "OneWay" && (
                    <div className='search-info-description opacity-5'>
                      {t("Book Return Ticket")}
                    </div>
                  )}
                </div>
              </Grid>
            )}
            {search?.type === "Multidestination" && (
              <Grid item xs={8}>
                <div className='search-info-container'>
                  <div className='search-info-title'>From</div>
                  <div className='search-info-description'>
                    {getMultidestinationDestinations()}
                    {getMultidestinationViaDestinations()}
                  </div>
                </div>
              </Grid>
            )}
            <Grid item xs={2}>
              <div className='search-info-container'>
                <div className='search-info-title'>{t("Pax")} & {t("Class")}</div>
                {search?.adult + search?.infant + search?.children > 1 ? (
                  <div className='search-info-description'>
                    {search?.adult + search?.infant + search?.children} {t("Travellers")},{" "}
                    {t(search?.class)}
                  </div>
                ) : (
                  <div className='search-info-description'>
                    {search?.adult + search?.infant + search?.children} {t("Traveller")},{" "}
                    {t(search?.class)}
                  </div>
                )}
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={2} className='modify-search-button-container'>
          <div
            className='modify-search-block pointer'
            onClick={() => (loading ? "" : modifySearch())}>
            {t("MODIFY")}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default ModifySearchBar;
