import React, { useState } from "react";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import clsx from "clsx";
import useCheckboxStyles from "../../../hooks/useCheckboxStyles";
import "./FilterComponents.css";
import { useTranslation } from "react-i18next";

function FareType({ search, setSearch, toggleDrawer }) {
  const classes = useCheckboxStyles();

  const { t } = useTranslation();
  const handleChange = (event) => {
    const existingSearch = { ...search };
    existingSearch.filters.fareType.find(
      (item) => item.title === event.target.name
    ).checked = event.target.checked;
    // toggleDrawer(false);
    setSearch(existingSearch);
  };

  return (
    <div className="number-of-stops-container mt-12 mb-16">
      <FormControl component="fieldset">
        <FormGroup>
          {search.filters.fareType &&
            search.filters.fareType.length > 0 &&
            search.filters.fareType.map((fareType, index) => (
              <div className="stops-item-container" key={index}>
                <div className="stop-item-title">
                  <FormControlLabel
                    control={
                      <Checkbox
                        className={classes.root}
                        color="default"
                        checkedIcon={
                          <span
                            className={clsx(classes.icon, classes.checkedIcon)}
                          />
                        }
                        icon={<span className={classes.icon} />}
                        checked={fareType.checked}
                        onChange={handleChange}
                        name={fareType.title}
                      />
                    }
                    label={fareType.title}
                  />
                </div>
              </div>
            ))}
        </FormGroup>
      </FormControl>
    </div>
  );
}

export default FareType;
