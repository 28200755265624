import React, { useState, useEffect } from "react";
import "./BasicSearchBar.css";
import Grid from "@material-ui/core/Grid";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import Menu from "@material-ui/core/Menu";
import DestinationSearchMenu from "../DestinationSearchMenu/DestinationSearchMenu";
import Popover from "@material-ui/core/Popover";
import DateRangePicker from "../DatePicker/DatePicker";
import months from "../../data/Months";
import Days from "../../data/Days";
import ImportExportRoundedIcon from "@material-ui/icons/ImportExportRounded";
import Tooltip from "@material-ui/core/Tooltip";
import moment from "moment";

function BasicSearchBar({ search, setSearch, t }) {
  const currentDate = moment();
  const [peopleClassMenuAnchorEl, setPeopleClassMenuAnchorEl] = useState(null);
  const [fromSearchMenuAnchorEl, setFromSearchMenuAnchorEl] = useState(null);
  const [toSearchMenuAnchorEl, setToSearchMenuAnchorEl] = useState(null);
  const [datePickerMenuAnchorEl, setDatePickerMenuAnchorEl] = useState(null);
  const [flag, setFlag] = useState("");
  const [displayDate, setDisplayDate] = useState(new Date());
  const [displayReturnDate, setDisplayReturnDate] = useState(new Date());
  const [departDate, setDepartDate] = useState(new Date());
  const [returnDate, setReturnDate] = useState(new Date());


  const fromSearchMenuHandleClick = (event) => {
    setFromSearchMenuAnchorEl(event.currentTarget);
  };

  const fromSearchMenuAnchorElHandleClose = (event) => {
    setFromSearchMenuAnchorEl(null);
  };

  const toSearchMenuHandleClick = (event) => {
    setToSearchMenuAnchorEl(event.currentTarget);
  };

  const toSearchMenuAnchorElHandleClose = (event) => {
    setToSearchMenuAnchorEl(null);
  };

  const datePickerMenuHandleClick = (event) => {
    setDatePickerMenuAnchorEl(event.currentTarget);
  };

  const datePickerMenuHandleClose = () => {
    setDatePickerMenuAnchorEl(null);
  };

  const peopleClassMenuHandleClick = (event) => {
    setPeopleClassMenuAnchorEl(event.currentTarget);
  };

  const peopleClassMenuHandleClose = () => {
    setPeopleClassMenuAnchorEl(null);
  };

  const changeToReturnTrip = () => {
    const existingSearch = { ...search };
    existingSearch.type = "RoundTrip";
    setDatePickerMenuAnchorEl(true);
    // existingSearch.depart = "";
    existingSearch.return = "";
    // existingSearch.return = getNextDay(existingSearch.depart);
    setSearch(existingSearch);
  };

  const getNextDay = (day) => {
    const nextDay = new Date(day);
    nextDay.setDate(day.getDate() + 2);
    return nextDay;
  };
  useEffect(() => {
    console.log("BasicSearchBar search", search);
    let depdate = search.depart;
    let retdate = search.return ? (search.return) : moment(depdate).add(1, 'd').$d;
    setDepartDate(() => (depdate));
    setReturnDate(() => (retdate));
    setDisplayDate(() => depdate < (currentDate) ? currentDate : depdate)
    setDisplayReturnDate(() => (retdate < (currentDate) && retdate < (depdate)) ? null : retdate)
    console.log("displayDate", displayDate, "displayReturnDate", displayReturnDate);
  }, [search]);
  useEffect(() => {
    const lastSearch = JSON.parse(localStorage.getItem("lastSearch"));
    if (lastSearch) {
      lastSearch.return = lastSearch.return ? moment(lastSearch.return) : moment(lastSearch.depart).add(1, 'd');
      let depDate = lastSearch.depart;
      let retdate = lastSearch.return;
      setDepartDate(() => lastSearch.depart);
      setReturnDate(() => lastSearch.return);
      setDisplayDate(() => depDate < (currentDate) ? currentDate : depDate)
      setDisplayReturnDate(() => (retdate < (currentDate) && retdate < (depDate)) ? null : retdate)
      setSearch(lastSearch);
    }
  }, []);
  const interchangeLocations = () => {
    const existingSearch = { ...search };
    if (existingSearch.from !== "From" && existingSearch.to !== "To") {
      const from = existingSearch.from;
      existingSearch.from = existingSearch.to;
      existingSearch.to = from;
      setSearch(existingSearch);
    }
  };

  return (
    <div>
      <div className="basic-search-bar">
        <Grid container className="full-height">
          <Grid
            item
            xs={search?.type === "OneWay" || search?.type === "RoundTrip" ? 3 : 4}
            className="pl-16 br-1 hover-fill relative"
          >
            <Menu
              className="top-z-index"
              id="fromSearchMenu"
              anchorEl={fromSearchMenuAnchorEl}
              keepMounted
              open={Boolean(fromSearchMenuAnchorEl)}
              onClose={fromSearchMenuAnchorElHandleClose}
            >
              <DestinationSearchMenu
                search={search}
                setSearch={setSearch}
                type="from"
                closeMenu={fromSearchMenuAnchorElHandleClose}
              />
            </Menu>
            <div
              className="from-to-height-width"
              onClick={fromSearchMenuHandleClick}
            >
              <div
                className={`bold small-font medium-opacity pointer heading-font ${typeof search?.from !== "string" ? "" : "no-opacity"
                  }`}
              >
                {t('From')}
              </div>
              <Tooltip title="Leaving From">
                <div
                  className="station-text pointer"
                  onClick={fromSearchMenuHandleClick}
                >
                  {search?.from?.city ? search?.from?.city : t('From')}
                </div>
              </Tooltip>
              <div
                className="description pointer"
                onClick={fromSearchMenuHandleClick}
              >
                {search?.from?.city_code ? `${search?.from?.city_code},` : ""}{" "}
                {search?.from?.airport_name ? search?.from?.airport_name : ""}
              </div>
              {search?.type !== "Multidestination" && (
                <div
                  className="destination-icon pointer"
                  onClick={() => interchangeLocations()}
                >
                  <ImportExportRoundedIcon className="rotate-90" />
                </div>
              )}
            </div>
          </Grid>

          <Grid
            item
            xs={search?.type === "OneWay" || search?.type === "RoundTrip" ? 3 : 4}
            className={
              "br-1 hover-fill " +
              (search?.type === "Multidestination" ? " pl-16" : " pl-24")
            }
          >
            <Menu
              id="toSearchMenu"
              anchorEl={toSearchMenuAnchorEl}
              keepMounted
              open={Boolean(toSearchMenuAnchorEl)}
              onClose={toSearchMenuAnchorElHandleClose}
            >
              <DestinationSearchMenu
                search={search}
                setSearch={setSearch}
                type="to"
                closeMenu={toSearchMenuAnchorElHandleClose}
              />
            </Menu>
            <div
              className="from-to-height-width"
              onClick={toSearchMenuHandleClick}
            >
              <div
                className={`bold small-font medium-opacity pointer heading-font ${typeof search?.to !== "string" ? "" : "no-opacity"
                  }`}
              >
                {t('To')}
              </div>
              <Tooltip title="Going To">
                <div
                  className="station-text pointer"
                  onClick={toSearchMenuHandleClick}
                >
                  {search?.to?.city ? search?.to?.city : t('To')}
                </div>
              </Tooltip>
              <div
                className="description pointer"
                onClick={toSearchMenuHandleClick}
              >
                {search?.to?.city_code ? `${search?.to?.city_code},` : ""}{" "}
                {search?.to?.airport_name ? search?.to?.airport_name : ""}
              </div>
            </div>
          </Grid>

          <Grid
            item
            xs={search?.type === "OneWay" || search?.type === "RoundTrip" ? 3 : 4}
            className="pl-16 br-1 hover-fill"
          >
            <Popover
              id="departDatePicker"
              anchorEl={datePickerMenuAnchorEl}
              keepMounted
              open={Boolean(datePickerMenuAnchorEl)}
              onClose={datePickerMenuHandleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <DateRangePicker
                search={search}
                setSearch={setSearch}
                closeMenu={datePickerMenuHandleClose}
                flag={flag}
              />
            </Popover>
            <div className="bold small-font medium-opacity search-title pointer heading-font">
              {t("Depart")}
              <div className="expand-icon pointer">
                <ExpandMoreRoundedIcon className="icon-blue" fontSize="small" />
              </div>
            </div>
            <div
              className="station-text pointer"
              onClick={(event) => {
                datePickerMenuHandleClick(event);
                setFlag("Depart");
              }}
            >
              {search && search?.depart && new Date(search?.depart).getDate() ? (
                <>
                  {" "}
                  {new Date(displayDate).getDate()}
                  <span className="station-sub-text">
                    {months[new Date(displayDate).getMonth()]},
                    {new Date(displayDate).getFullYear().toString().substring(2, 4)}
                  </span>

                </>
              ) : (
                <span
                  className="station-sub-text"
                  onClick={(event) => {
                    datePickerMenuHandleClick(event);
                    setFlag("Depart");
                  }}
                >
                  {""}
                </span>
              )}
            </div>
            <div
              className="description pointer"
              onClick={(event) => {
                datePickerMenuHandleClick(event);
                setFlag("Depart");
              }}
            >
              {search?.depart ? Days[(new Date(search?.depart)).getDay()] : ""}
            </div>
          </Grid>

          {(search?.type === "OneWay" || search?.type === "RoundTrip") && (
            <Grid item xs={3} className="pl-16 br-1 hover-fill">
              <div className="bold small-font medium-opacity search-title pointer heading-font">
                {t('Return')}
                <div className="expand-icon">
                  <ExpandMoreRoundedIcon
                    className="icon-blue"
                    fontSize="small"
                  />
                </div>
              </div>
              {search?.type === "OneWay" && (
                <div
                  className="reference-text pointer"
                  onClick={() => {
                    changeToReturnTrip();
                    setFlag("Return");
                  }}
                >
                  {t("Book return tickets for great discounts")}
                </div>
              )}
              {search?.type === "RoundTrip" && (
                <div
                  className="station-text pointer"
                  onClick={(event) => {
                    datePickerMenuHandleClick(event);
                    setFlag("Return");
                  }}
                >
                  {displayReturnDate ? new Date(displayReturnDate).getDate() : ""}
                  {displayReturnDate ? (
                    <span className="station-sub-text">
                      {months[new Date(displayReturnDate).getMonth()]},
                      {new Date(displayReturnDate).getFullYear().toString().substring(2, 4)}
                    </span>
                  ) : (
                    <span
                      className="station-sub-text"
                      onClick={(event) => {
                        datePickerMenuHandleClick(event);
                        setFlag("Return");
                      }}
                    >
                      {" "}
                    </span>
                  )}
                </div>
              )}
              {search?.type === "RoundTrip" && (
                <div
                  className="description pointer"
                  onClick={(event) => {
                    datePickerMenuHandleClick(event);
                    setFlag("Return");
                  }}
                >
                  {search?.return ? Days[(new Date(search?.return)).getDay()] : ""}
                </div>
              )}
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
}

export default BasicSearchBar;
