import {
  Button,
  Container,
  Grid,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import "./ClientRoleRights.css";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import ClientRoleDrawer from "./ClientRoleDrawer/ClientRoleDrawer";
import Drawer from "@material-ui/core/Drawer";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Fade from '@mui/material/Fade';
import { useTranslation } from "react-i18next";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function ClientRoleRights() {
  const { t } = useTranslation();
  const [roles, setRoles] = useState([]);
  const [roleNameSelected, setRoleNameSelected] = useState("");
  const [roleIdSelected, setRoleNameIdSelected] = useState(0);
  const [rolePermissions, setrolePermissions] = useState(null);
  const [clientID, setClientID] = useState(0);
  const [drawer, setDrawer] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState();
  const [expanded, setExpanded] = React.useState(true);
  const [transPermissions, setTransPermissions] = useState({
    isManageUser: false,
    isPayment: false,
    isManageTransaction: false,
    isTransactionReport: false,
    isManageBooking: false,
    isAccountStatement: false,
    isMarkUp: false,
    isMyWallet: false,
    isTravelSchedule: false,
    isViewCancellation: false
  });
  const [sbPermission, setSBPermission] = useState({
    Flight: { isSearch: false, isPrice: false, isBook: false },
    Rail: { isSearch: false, isPrice: false, isBook: false },
    Hotel: { isSearch: false, isPrice: false, isBook: false },
  });

  const [pageProfilePermissions, setpageProfilePermissions] = useState({
    isShowNetfares: false,
    isYouEarn: false,
  });

  const initialCpm = [
    {
      permissionId: 0,
      clientPermissionType: 'SearchAndBook',
      isSelected: false,
      searchAndBookDetails: [
        {
          permissionId: 1,
          clientPermissionType: 'FlightbookDetail',
          isSelected: false,
          details: {},
        },
        {
          permissionId: 2,
          clientPermissionType: 'RailbookDetail',
          isSelected: false,
          details: {},
        },
        {
          permissionId: 3,
          clientPermissionType: 'HotelbookDetail',
          isSelected: false,
          details: {},
        },
      ],
    },
    {
      permissionId: 4,
      clientPermissionType: "TransactionPermissionDetails",
      isSelected: false,
      transactionDetails: transPermissions,
    },
    {
      permissionId: 5,
      clientPermissionType: 'ProfilePermissionDetails',
      isSelected: false,
      pageProfileDetails: pageProfilePermissions,
    },
  ];
  const [clientPermissionsModels, setClientPermissionsModels] = useState(initialCpm);
  const [showCB, setShowCB] = useState(false)
  const [popUpMsg, setPopUpMsg] = useState(false)
  const [errMsg, setErrMsg] = useState("")
  const [updateType, setUpdateType] = useState("")


  async function getRoles() {
    const user = JSON.parse(localStorage.getItem("user"));
    try {
      const response1 = await axios.get(
        process.env.REACT_APP_CORE_URL + "api/Configuration/ClientPermissions/GetClientRoles/" + user["clientref_id"]
      );

      if (response1.data) {
        console.log(response1.data);
        setRoles(response1.data);


        const hasFinanceTeamRole = response1.data.some(role => role.roleName === "FinanceTeamRole");

        if (!hasFinanceTeamRole) {
          const response2 = await axios.get(
            process.env.REACT_APP_CORE_URL + "api/Configuration/ClientPermissions/FetchCommonUserRoles/" + user["clientref_id"]
          );
          console.log(response2.data);
        }
      } else {
        console.log("no roles");
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        try {
          const response2 = await axios.get(
            process.env.REACT_APP_CORE_URL + "api/Configuration/ClientPermissions/FetchCommonUserRoles/" + user["clientref_id"]
          );
          console.log(response2.data);
        } catch (fetchError) {
          console.error("Error fetching common user roles:", fetchError);
        }
      } else {
        console.error("Error fetching roles:", error);
      }
    }
  }






  async function getRolePermissions(roleId, clientId, roleName, roleIndex) {

    setRoleNameSelected(roleName);
    setRoleNameIdSelected(roleId);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_CORE_URL}api/Configuration/ClientPermissions/${roleId}`
      );

      const res = response.data;
      console.log(res);

      if (res.clientPermissionsModels) {
        setClientPermissionsModels(res.clientPermissionsModels)
      }
      else {
        setClientPermissionsModels(initialCpm)
      }
      setShowCB(true);
    } catch (error) {
      console.error('Error fetching role permissions:', error);
    }
  }




  useEffect(() => {
    getRoles();
    const user = JSON.parse(localStorage.getItem("user"));
    setClientID(user["clientref_id"]);
  }, []);



  const handleChange = (event) => {
    console.log(event.target.name);
    if (event.target.checked === true) {
      rolePermissions[event.target.name].isSelected = true;
    } else {
      rolePermissions[event.target.name].isSelected = false;
    }

    setrolePermissions({
      ...rolePermissions,
    });
    console.log(rolePermissions);
  };

  const toggleDrawer = (value) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawer(value);
  };

  const openDrawer = (selectedItem) => {
    setDrawer(true);
  };

  const handleSnackbarToggle = () => {
    setDrawer(false);
    setSnackbarOpen(true);
  };

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  async function handleSavePermissions() {

    setUpdateType("Permissions");
    const headers = {
      "Content-Type": "application/json",
    };

    await axios
      .post(
        process.env.REACT_APP_CORE_URL +
        "api/Configuration/ClientPermissions/ModifyUserAccessRights",
        JSON.stringify({
          RoleId: roleIdSelected,
          ClientId: parseInt(clientID),
          RoleName: roleNameSelected,
          ClientPermissionsModels: clientPermissionsModels,
        }),
        {
          headers,
        }
      )
      .then((res) => {
        console.log(res);
        handleSnackbarToggle();
        setPopUpMsg(true);
      })
      .catch((err) => {
        console.log(err);
        handleSnackbarToggle();
        setPopUpMsg(false);
      });


    getRoles();
  }

  const handleListItemClick = (roleId, clientId, roleName, index) => {
    setSelectedIndex(index);
    getRolePermissions(roleId, clientId, roleName, index);
  };



  const handleExpansion = (event) => {
    if (event.target.closest('.expand-icon')) {
      setExpanded((prevExpanded) => !prevExpanded);
    }
  };

  const categories = [
    {
      title: 'Flight',
      actions: ['Search', 'Price', 'Book']
    },
    {
      title: 'Rail',
      actions: ['Search', 'Price', 'Book']
    },
    {
      title: 'Hotel',
      actions: ['Search', 'Price', 'Book']
    }
  ];

  const handlePermissionChange = (modelIndex, detailIndex, action) => (event) => {

    const updatedClientPermissions = [...clientPermissionsModels];
    console.log('updatedClientPermissions', updatedClientPermissions);

    if (detailIndex === null) {
      updatedClientPermissions[modelIndex].isSelected = event.target.checked;

      if (modelIndex === 0) {
        updatedClientPermissions[modelIndex].searchAndBookDetails.forEach((detail) => {
          detail.details.isSearch = event.target.checked;
          detail.details.isPrice = event.target.checked;
          detail.details.isBook = event.target.checked;
          detail.details.isPriceDisabled = event.target.checked;
          detail.details.isSearchDisabled = event.target.checked;
        });
      } else if (modelIndex === 1) {
        Object.keys(updatedClientPermissions[modelIndex].transactionDetails).forEach((key) => {
          updatedClientPermissions[modelIndex].transactionDetails[key] = event.target.checked;
        });
      } else if (modelIndex === 2) {
        Object.keys(updatedClientPermissions[modelIndex].pageProfileDetails).forEach((key) => {
          updatedClientPermissions[modelIndex].pageProfileDetails[key] = event.target.checked;
        });
      }
    } else {
      const detail = updatedClientPermissions[modelIndex].searchAndBookDetails[detailIndex].details;
      detail[`is${action}`] = event.target.checked;

      if (action === 'Book') {
        detail.isPrice = event.target.checked;
        detail.isSearch = event.target.checked;
        detail.isPriceDisabled = event.target.checked;
        detail.isSearchDisabled = event.target.checked;
      } else if (action === 'Price') {
        detail.isSearch = event.target.checked;
        detail.isSearchDisabled = event.target.checked;
      }

      if (!detail.isBook) {
        detail.isPriceDisabled = false;
        detail.isSearchDisabled = detail.isPrice ? true : false;
      } else if (!detail.isPrice) {
        detail.isSearchDisabled = false;
      }

      const allChecked = updatedClientPermissions[modelIndex].searchAndBookDetails.every(detail =>
        detail.details.isSearch && detail.details.isPrice && detail.details.isBook
      );
      updatedClientPermissions[modelIndex].isSelected = allChecked;
    }

    setClientPermissionsModels(updatedClientPermissions);
  };

  const handleTransactionPermissionChange = (key) => (event) => {
    const updatedPermissions = [...clientPermissionsModels];
    updatedPermissions[1].transactionDetails[key] = event.target.checked;

    const allChecked = Object.keys(updatedPermissions[1].transactionDetails).every(
      k => updatedPermissions[1].transactionDetails[k]
    );

    updatedPermissions[1].isSelected = allChecked;

    setClientPermissionsModels(updatedPermissions);
  };


  const handleProfilePermissionChange = (key) => (event) => {
    const updatedPermissions = [...clientPermissionsModels];
    updatedPermissions[2].pageProfileDetails[key] = event.target.checked;

    const allChecked = Object.keys(updatedPermissions[2].pageProfileDetails).every(
      k => updatedPermissions[2].pageProfileDetails[k]
    );

    updatedPermissions[2].isSelected = allChecked;

    setClientPermissionsModels(updatedPermissions);
  };



  return (
    <div>
      <Container>
        <Grid container spacing={0}>
          <Grid item xs={3}>
            <div className="sidebar-container">
              <div className="sidebar-head">
                {t("Roles List")}
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{ marginLeft: "auto" }}
                  onClick={() => {
                    setDrawer(true);
                  }}
                >
                  {t("Add")}
                </Button>
              </div>
              <Divider />
              <List component="nav" aria-label="secondary mailbox folders">
                {roles &&
                  roles.map((row, index) => (
                    <ListItem
                      button
                      selected={selectedIndex === index}
                      onClick={() => handleListItemClick(row.roleId, row.clientId, row.roleName, index)}
                    >
                      <ListItemText primary={row.roleName} />
                    </ListItem>
                  ))}
              </List>
            </div>
          </Grid>
          <Grid item xs={9}>
            <div className="main-container">
              <div className="sidebar-heading">
                {t("Permissions for role")}:{" "}
                {roles[selectedIndex] && roles[selectedIndex].roleName}
              </div>
              <Divider />
              {showCB ? (<div>
                <div className="mt-4">
                  <Accordion
                    expanded={expanded}
                    onChange={handleExpansion}
                    TransitionComponent={Fade}
                    transitionDuration={400}
                    sx={{
                      '& .MuiAccordion-region': { height: expanded ? 'auto' : 0 },
                      '& .MuiAccordionDetails-root': { display: expanded ? 'block' : 'none' },
                    }}
                  >
                    <AccordionSummary
                      // expandIcon={<ExpandMoreIcon className="expand-icon" />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                      style={{ backgroundColor: "rgb(229 229 229 / 35%)" }}
                      onClick={handleExpansion}
                    >
                      <Typography>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={clientPermissionsModels[0].isSelected}
                              onChange={handlePermissionChange(0, null, null)}
                              color="primary"
                            />
                          }
                          label=""
                        /> {t("Search & Booking")}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <div className='d-flex justify-content-between ps-5 pt-2 pe-5'>
                          {clientPermissionsModels[0]?.searchAndBookDetails?.map((detail, detailIndex) => (
                            <Grid item xs={12} key={detail.permissionId}>
                              <div className="category">
                                <h5 style={{ color: '#9f155a' }}>
                                  {detail.clientPermissionType === "FlightbookDetail" ? "Flight" :
                                    (detail.clientPermissionType === "RailbookDetail" ? "Rail" : "Hotel")}
                                </h5>
                                <ul style={{ listStyleType: 'none', paddingLeft: '0rem' }}>
                                  {categories[detailIndex].actions.map((action, idx) => (
                                    <li key={idx}>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={detail.details[`is${action}`] || false}
                                            onChange={handlePermissionChange(0, detailIndex, action)}
                                            color="primary"
                                            disabled={
                                              action === 'Price' ? detail.details.isPriceDisabled :
                                                action === 'Search' ? detail.details.isSearchDisabled : false
                                            }
                                          />
                                        }
                                        label={action}
                                      />


                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </Grid>
                          ))}
                        </div>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>


                  <Accordion expanded={expanded}>
                    <AccordionSummary
                      // expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                      style={{ backgroundColor: "rgb(229 229 229 / 35%)" }}
                    >
                      <Typography>
                        <FormControlLabel control={
                          <Checkbox
                            checked={clientPermissionsModels[1].isSelected}
                            onChange={handlePermissionChange(1, null)}
                            color="primary"
                          />
                        }
                          label=""
                        /> {t("Transaction")}
                      </Typography>
                    </AccordionSummary>
                    <div className="permissions-box p-3">
                      <Grid container spacing={3}>
                        {Object.keys(clientPermissionsModels[1].transactionDetails).map((key) => (
                          <Grid item xs={3} key={key}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={clientPermissionsModels[1].transactionDetails[key]}
                                  onChange={handleTransactionPermissionChange(key)}
                                  color="primary"
                                />
                              }
                              label={key.replace(/^is/, '')}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </div>
                  </Accordion>

                  <Accordion expanded={expanded}>
                    <AccordionSummary
                      // expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel3-content"
                      id="panel3-header"
                      style={{ backgroundColor: "rgb(229 229 229 / 35%)" }}
                    >
                      <Typography>
                        <FormControlLabel control={
                          <Checkbox
                            checked={clientPermissionsModels[2].isSelected}
                            onChange={handlePermissionChange(2, null)}
                            color="primary"
                          />
                        }
                          label=""
                        /> {t("Profile")}
                      </Typography>
                    </AccordionSummary>
                    <Grid item xs={12}>
                      <div className="p-3">
                        <div className="mb-4">{t("Page Settings")}:</div>
                        {clientPermissionsModels[2]?.pageProfileDetails && Object.keys(clientPermissionsModels[2]?.pageProfileDetails).map((key) => (
                          <div key={key}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={clientPermissionsModels[2]?.pageProfileDetails[key]}
                                  onChange={handleProfilePermissionChange(key)}
                                  color="primary"
                                />
                              }
                              label={key == "isShowNetfares" ?
                                `${t("Show Net Fare on Display Page")}` : key == "isYouEarn" ? `${t("Display Show / Hide 'You Earn' on Display Page")}` : ""
                              }
                            />
                          </div>
                        ))}
                      </div>
                    </Grid>
                  </Accordion>
                </div>
                <div className="permission-action">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSavePermissions}
                  >
                    {t("Save Permissions")}
                  </Button>
                </div>
              </div>) : (
                <div className="noresult-msg">{t("Please choose a role!")}</div>
              )}

            </div>
          </Grid>
        </Grid>
      </Container>
      <Drawer
        anchor="left"
        open={drawer}
        onClose={toggleDrawer(false)}
        BackdropProps={{ invisible: true }}
        variant="persistent"
        className="drawer-width"
        transitionDuration={{ enter: 500, exit: 500 }}
      >
        <div className="drawer-min-width-40">
          <ClientRoleDrawer
            toggleDrawer={toggleDrawer}
            handleSnackbarToggle={handleSnackbarToggle}
            setPopUpMsg={setPopUpMsg}
            setUpdateType={setUpdateType}
            getRoles={getRoles}
            clientID={clientID}
            setErrMsg={setErrMsg}
          ></ClientRoleDrawer>
        </div>
      </Drawer>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackBarClose}
      >
        <Alert onClose={handleSnackBarClose} severity={popUpMsg ? "success" : "warning"}>
          {popUpMsg ? (updateType === "Permissions" ? `Role Permissions Updated` : `New Role Created`) :
            errMsg ? errMsg : `Warning! Something went wrong`}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default ClientRoleRights;
