import React, { useEffect, useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import { TextField, FormControlLabel, Checkbox } from "@material-ui/core";
import { Button } from "@material-ui/core";
import "./PricingOptions.css"
import axios from "axios";
import RiseLoading from "../Loader/RiseLoader";
import Typography from '@mui/material/Typography';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { TableHead } from "@material-ui/core";
import BrandedFareComponent from '../BrandedFareComponents/BrandedFareComponent'
import { filterAndSortBaggage } from "../../functions/filterAndSortBaggage";
import getSSRInfo from "../../functions/getSSRInfo";
import BrandedFareTable from "../BrandedFareComponents/BrandedFareTable";

const PricingOptions = ({
    setShowPriceOptions,
    showPriceOptions,
    supplier,
    pcc,
    clientId,
    pnr,
    selectedOption,
    setSelectedOption,
    adultBase,
    childBase,
    infantBase,
    adultTax,
    childTax,
    infantTax,
    getTax,
    setShowPriceCompare,
    results,
    bookingDetails,
    tripType,
    handleConfirmPrice,
    setPricingParams,
    showLoader,
    setShowLoader
}) => {
    const [selectedOptions, setSelectedOptions] = useState({
        Publish: false,
        AccountCode: false,
        CorporateCode: false,
        Private: false,
        VFR: false,
        BrandedFare: false,
    });

    const [code, setCode] = useState("");
    const [newAdultBase, setNewAdultBase] = useState(0);
    const [newChildBase, setNewChildBase] = useState(0);
    const [newInfantBase, setNewInfantBase] = useState(0);
    const [newAdultTax, setNewAdultTax] = useState(0);
    const [newChildTax, setNewChildTax] = useState(0);
    const [newInfantTax, setNewInfantTax] = useState(0);
    const [selectedItinerary, setSelectedItinerary] = useState(0);
    const [fareSegGroups, setFareSegGroups] = useState([]);
    const [activeTab, setActiveTab] = useState("fareSummary");
    const [baggageError, setBaggageError] = useState("");
    const [miniRuleError, setMiniRuleError] = useState("");


    const handleCheckboxChange = (e) => {
        const { name } = e.target;
        setSelectedOptions({
            Publish: false,
            AccountCode: false,
            CorporateCode: false,
            Private: false,
            VFR: false,
            BrandedFare: false,
            [name]: e.target.checked,
        });
    };

    const handleCodeChange = (e) => {
        setCode(e.target.value);
    };

    useEffect(() => {
        setCode("");
        setSelectedOptions([]);
        setSelectedOption([]);
    }, [showPriceOptions])


    const handlePriceButtonClick = async () => {
        setShowLoader(true);
        const selectedFareType = Object.keys(selectedOptions).find(
            (key) => selectedOptions[key] === true
        );

        setPricingParams({
            fareType: selectedFareType, code: code
        });

        if (!selectedFareType) {
            console.log('No fare type selected');
            setShowLoader(false);
            return;
        }

        const headers = {
            "Content-Type": "application/json",
        };

        let payload = {
            PNR: pnr,
            Supplier: supplier,
            PCC: pcc,
            ClientId: clientId,
            FareType: selectedFareType
        };

        if (selectedFareType === 'AccountCode') {
            payload.AccountCodeList = code;
        } else if (selectedFareType === 'CorporateCode') {
            payload.CorporateCodeList = code;
        }

        await axios
            .post(
                process.env.REACT_APP_CORE_URL +
                "Flights/Booking/ImportPNRTicketing/v1/ImportPricing",
                JSON.stringify(payload),
                { headers }
            )
            .then((res) => {
                console.log('Success:', res);
                setSelectedOption(res.data);
                setShowLoader(false);
                setShowPriceCompare(true);
            })
            .catch((err) => {
                console.log('Error:', err);
                setShowLoader(false);
            });
    };


    useEffect(() => {
        try {
            if (selectedOption && selectedOption.fareGroups && selectedOption.fareGroups.length > 0) {
                let totalPaxFare = 0;

                selectedOption.fareGroups.map(fareGroup => {
                    fareGroup.fares.map(fare => {
                        if (fare.paxType === "ADT") {
                            const adultBaseFare = parseFloat(fare.base).toFixed(2);
                            setNewAdultBase(adultBaseFare);

                            const adultTax = getTax(fare).toFixed(2);
                            setNewAdultTax(adultTax);

                        } else if (fare.paxType === "CHD") {
                            const childBaseFare = parseFloat(fare.base).toFixed(2);
                            setNewChildBase(childBaseFare);

                            const childTax = getTax(fare).toFixed(2);
                            setNewChildTax(childTax);

                        } else if (fare.paxType === "INF") {
                            const infantBaseFare = parseFloat(fare.base).toFixed(2);
                            setNewInfantBase(infantBaseFare);

                            const infantTax = getTax(fare).toFixed(2);
                            setNewInfantTax(infantTax);

                        }
                    });
                });
            }
        } catch (error) {
            console.error("Error calculating fares:", error);
        }
    }, [selectedOption]);

    return (
        <div className="pricing-options-container" >
            <div className="d-flex justify-content-between align-items-center rounded po-header">
                <div>Select the Price option to get requested fares: </div>
                <div><CloseIcon style={{ cursor: "pointer" }} onClick={() => setShowPriceOptions(false)} /></div>
            </div>

            <div className="d-flex mt-3">
                <div style={{ width: "300px" }} className="d-flex flex-column">
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="Publish"
                                checked={selectedOptions.Publish}
                                onChange={handleCheckboxChange}
                                color="primary"
                                className="rounded"
                            />
                        }
                        label={"Publish Fares"}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="VFR"
                                checked={selectedOptions.VFR}
                                onChange={handleCheckboxChange}
                                color="primary"
                            />
                        }
                        label="VFR Fares"
                    />
                    <div className="d-flex">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="AccountCode"
                                    checked={selectedOptions.AccountCode}
                                    onChange={handleCheckboxChange}
                                    color="primary"
                                />
                            }
                            label="Account Code"
                        />
                    </div>
                    {selectedOptions.AccountCode && <>< TextField
                        style={{ width: "250px" }}
                        variant="outlined"
                        label="Add Code"
                        className="bg-light rounded"
                        size="small"
                        value={code}
                        onChange={handleCodeChange}
                    />
                        <Typography variant="caption" color="textSecondary">
                            * Pls use "~" instead of Comma ( , ) for multiple codes
                        </Typography></>
                    }
                </div>
                <div style={{ width: "300px" }} className="d-flex flex-column">

                    <FormControlLabel
                        control={
                            <Checkbox
                                name="Private"
                                checked={selectedOptions.Private}
                                onChange={handleCheckboxChange}
                                color="primary"
                            />
                        }
                        label="Private Fares"
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                name="BrandedFare"
                                checked={selectedOptions.BrandedFare}
                                onChange={handleCheckboxChange}
                                color="primary"
                            />
                        }
                        label="Branded Fares"
                    />

                    <div className="d-flex">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="CorporateCode"
                                    checked={selectedOptions.CorporateCode}
                                    onChange={handleCheckboxChange}
                                    color="primary"
                                />
                            }
                            label="Corporate Code"
                        />
                    </div>
                    {selectedOptions.CorporateCode && <><TextField
                        style={{ width: "250px" }}
                        variant="outlined"
                        label="Add Code"
                        className="bg-light rounded"
                        size="small"
                        value={code}
                        onChange={handleCodeChange}
                    />
                        <Typography variant="caption" color="textSecondary">
                            * Pls use "~" instead of Comma ( , ) for multiple codes
                        </Typography></>}
                </div>
            </div>
            <div className="mt-3">
                <Button
                    className="srn-btn"
                    onClick={handlePriceButtonClick}
                >
                    Price
                </Button>
            </div>

            {selectedOption?.fareGroups?.length > 0 && selectedOptions.BrandedFare &&
                (<div className="mt-3 ">
                    <BrandedFareTable
                        result={selectedOption}
                        selectedItinerary={selectedItinerary}
                        setSelectedItinerary={setSelectedItinerary}
                        tripType={tripType}
                        adtCount={results && results.adultCount}
                        chdCount={results && results.childCount}
                        infCount={results && results.infantCount}
                    />
                </div>)}

            {selectedOption?.fareGroups?.length > 0 ? (
                <div className="mt-3 ">
                    <span className="text-bold" style={{ color: "#9f155a" }}>Old Fare</span>
                    <Table size="small" className="table border">
                        <TableHead>
                            <TableRow>
                                <TableCell>Pax</TableCell>
                                <TableCell>Base Fare</TableCell>
                                <TableCell>Tax</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {results?.flightFares?.map((fare, index) => (
                                <TableRow key={index}>
                                    <TableCell>{fare.paxType}</TableCell>

                                    <TableCell>
                                        {(fare.baseFare).toFixed(2)}
                                    </TableCell>

                                    <TableCell>
                                        {(fare.taxes.reduce((sum, tax) => sum + tax.amt, 0)).toFixed(2)}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>

                    <span className="mt-2 text-bold" style={{ color: "#9f155a" }}>New Fare</span>
                    <Table size="small" className="border">
                        <TableHead>
                            <TableRow>
                                <TableCell>Pax</TableCell>
                                <TableCell>Base Fare</TableCell>
                                <TableCell>Tax</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {selectedOption?.fareGroups[0]?.fares
                                .sort((a, b) => {
                                    const order = ["ADT", "CHD", "INF"];
                                    return order.indexOf(a.paxType) - order.indexOf(b.paxType);
                                })
                                .map((pax, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{pax.paxType}</TableCell>
                                        <TableCell>{pax.paxType === "ADT" ? newAdultBase : pax.paxType === "CHD" ? newChildBase : newInfantBase}</TableCell>
                                        <TableCell>{pax.paxType === "ADT" ? newAdultTax : pax.paxType === "CHD" ? newChildTax : newInfantTax}</TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>

                </div>
            ) : null}



            {selectedOption?.fareGroups?.length > 0 ? (<div className="mt-2 d-flex justify-content-between">
                <div></div>
                <div>
                    <Button
                        className="srn-btn"
                        onClick={handleConfirmPrice}
                    >
                        Confirm
                    </Button>
                </div>

            </div>) : null}

            {showLoader && <RiseLoading isLoading={showLoader} />}

        </div>
    );
};

export default PricingOptions;
