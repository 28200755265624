import React, { useEffect, useState } from "react";
import "./UserDrawer.css";
import CloseIcon from "@material-ui/icons/Close";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { IconButton } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import axios from "axios";
// import "react-multi-email/style.css";
import { checkIfUserStaff } from "../../../services/AuthService";
import PasswordStrengthBar from "react-password-strength-bar";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import Dialog from "@mui/material/Dialog";
import Otp from "../../Login/Otp/Otp";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles({
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

const MustContainItem = (props) => {
  const { data } = props;
  const label = data[0];
  const meetsReq = data[1];

  const setClass = () => {
    const classArr = ["must-line"];
    if (meetsReq) classArr.push("cross-out");
    return classArr.join(" ");
  };

  return (
    <div className="MustContainItem">
      <div className="must-item">
        <li className="must-text">{label}</li>
        <div className={setClass()}></div>
      </div>
    </div>
  );
};

export default function ClientDrawer({
  toggleDrawer,
  handleSnackbarToggle,
  getClients,
  id,
  setDrawer,
  action
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [emails, setEmails] = useState([]);
  const [message, setMessage] = useState("");
  const [IsResStatus, setResStatus] = useState(false);
  const [isLive, setisLive] = useState(false);
  const [isActive, setIsActive] = useState();
  const [roleList, setRoleList] = useState([]);
  const [role, setRole] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [invalidEmails, setinvalidEmails] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dob, setDob] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [title, setTitle] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  // booleans for password validations
  const [containsUL, setContainsUL] = useState(false); // uppercase letter
  const [containsLL, setContainsLL] = useState(false); // lowercase letter
  const [containsN, setContainsN] = useState(false); // number
  const [containsSC, setContainsSC] = useState(false); // special character
  const [contains8C, setContains8C] = useState(false); // min 8 characters
  const [passwordMatch, setPasswordMatch] = useState(false); // passwords match
  const [isTAC, setTAC] = useState(false);
  const [clientId, setClientId] = useState("");
  const [rolename, setRolename] = useState("");
  const [maxDob, setMaxDob] = useState(null);

  const [passwordType, setPasswordType] = useState("password");

  const [eye, seteye] = useState(true);
  const [DOBerror, setDOBerror] = useState(true);
  // checks all validations are true
  const [allNotValid, setAllNotValid] = useState(true);
  const [saveStatusCode, setSaveStatusCode] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [otpPopUp, setOtpPopUp] = useState(false);
  const [otp, setOtp] = useState(new Array(4).fill(''));
  const [resOtpTime, setResOtpTime] = useState("");
  const [showOtp, setShowOtp] = useState(false);
  const [expired, setExpired] = useState(0);
  const [resMsg, setResMsg] = useState("");
  const [notValid, setNotValid] = useState(false);
  const [disableOtpInput, setDisableOtpInput] = useState(false);
  const [retryCount, setRetryCount] = useState(false);

  const mustContainData = [
    [t("An uppercase letter (A-Z)"), containsUL],
    [t("A lowercase letter (a-z)"), containsLL],
    [t("A number (0-9)"), containsN],
    [t("A special character (!@#$)"), containsSC],
    [t("At least 8 characters"), contains8C],
    [t("Passwords match"), passwordMatch],
  ];

  const validatePassword = () => {
    // Local variables for validations
    let hasUppercase = false;
    let hasLowercase = false;
    let hasNumber = false;
    let hasSpecialChar = false;
    let hasMinLength = false;
    let passwordsMatch = false;

    // has uppercase letter
    if (password.toLowerCase() !== password) hasUppercase = true;

    // has lowercase letter
    if (password.toUpperCase() !== password) hasLowercase = true;

    // has number
    if (/\d/.test(password)) hasNumber = true;

    // has special character
    if (/[~`!@#$%^&*+=\-[\]\\';,/{}|\\":<>?]/g.test(password)) hasSpecialChar = true;

    // has 8 characters
    if (password.length >= 8) hasMinLength = true;

    // passwords match
    if (password !== "" && password === confirmPassword) passwordsMatch = true;

    // Update state variables based on local variables
    setContainsUL(hasUppercase);
    setContainsLL(hasLowercase);
    setContainsN(hasNumber);
    setContainsSC(hasSpecialChar);
    setContains8C(hasMinLength);
    setPasswordMatch(passwordsMatch);

    // all validations passed
    if (hasUppercase && hasLowercase && hasNumber && hasSpecialChar && hasMinLength && passwordsMatch) {
      setAllNotValid(false);
    } else {
      setAllNotValid(true);
    }
  };

  const statusOptions = [
    { type: "Active", value: true },
    { type: "In-active", value: false },
  ];
  const titleOptions = [
    { value: 'Mr', label: 'Mr' },
    { value: 'Mrs', label: 'Mrs' },
    { value: 'Ms', label: 'Ms' },
  ];
  useEffect(() => {
    if (emails.length == 0) {
      setinvalidEmails([]);
      setEmailError(false);
    }
  }, [emails]);


  const ResetUserForm = () => {
    setTitle("");
    setFirstName("");
    setLastName("");
    setDob("");
    setContactNumber("");
    setEmail("");
    setPassword("");
    setConfirmPassword("");
    setRole([]);
    setMessage("");
    setContainsUL(false);
    setContainsLL(false);
    setContainsN(false);
    setContainsSC(false);
    setContains8C(false);
    setPasswordMatch(false);
  }


  async function getRoles() {
    if (checkIfUserStaff()) {
      try {
        const response = await axios.get(process.env.REACT_APP_IDENTITY_SERVER + "ids/Role");
        if (response.data) {
          console.log(response.data);
          setRoleList(response.data);
        } else {
          console.log("no roles");
        }
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    } else {
      const clientId = new URL(window.location.href).pathname.split("/").pop();
      try {
        const response1 = await axios.get(
          process.env.REACT_APP_CORE_URL + "api/Configuration/ClientPermissions/GetClientRoles/" + clientId
        );

        if (response1.data) {
          let rolesArr = [];
          let hasFinanceTeamRole = false;

          for (let i = 0; i < response1.data.length; i++) {
            rolesArr.push({
              roleId: response1.data[i].roleId,
              name: response1.data[i].roleName,
            });
            if (response1.data[i].roleName === "FinanceTeamRole") {
              hasFinanceTeamRole = true;
            }
          }

          setRoleList(rolesArr);
          console.log(rolesArr);

          if (!hasFinanceTeamRole) {
            const response2 = await axios.get(
              process.env.REACT_APP_CORE_URL + "api/Configuration/ClientPermissions/FetchCommonUserRoles/" + clientId
            );
            console.log(response2.data);
          }
        } else {
          console.log("no roles");

          const response2 = await axios.get(
            process.env.REACT_APP_CORE_URL + "api/Configuration/ClientPermissions/FetchCommonUserRoles/" + clientId
          );
          console.log(response2.data);
        }
      } catch (error) {
        console.error("Error fetching roles:", error);

        try {
          const response2 = await axios.get(
            process.env.REACT_APP_CORE_URL + "api/Configuration/ClientPermissions/FetchCommonUserRoles/" + clientId
          );
          console.log(response2.data);
        } catch (fetchError) {
          console.error("Error fetching common user roles:", fetchError);
        }
      }
    }
  }


  useEffect(() => {
    setMessage("");
    setResStatus(false);
    getRoles();
  }, []);


  function handleRegisterUser(mode) {
    console.log(emails);
    console.log(isActive);
    console.log(role);
    const user = JSON.parse(localStorage.getItem("user"));
    const headers = {
      "Content-Type": "application/json",
    };
    let url =
      process.env.REACT_APP_IDENTITY_SERVER + "ids/User/RegisterNewUser/";
    axios
      .post(
        url,
        JSON.stringify({
          email: email,
          isStaff: true,
          clientId: new URL(window.location.href).pathname.split("/").pop(),
          status: isActive,
          firstName: firstName,
          lastName: lastName,
          dob: dob,
          contactNumber: contactNumber,
          title: title,
          password: password,
          IsEmailConfirmed: true,
          UserId: user["user_id"],
          OTP: (otp.join('') === '' || mode === "new") ? 0 : otp.join(''),
        }),
        {
          headers,
        }
      )
      .then((res) => {
        if (res.data.message && res.data.statusCode !== 200) {
          if (res.data.message.toLowerCase().includes("invalid otp")) {
            setResMsg(t("Invalid OTP"));
            setOtp(new Array(4).fill(''));
            setNotValid(true);
          }
          else if ((res.data.message).toLowerCase().includes("retry count:")) {
            setResMsg(t("Too many incorrect OTP attempts. Please try after sometime"));
            localStorage.setItem("otpFail", true);
            setDisableOtpInput(true);
            setRetryCount(true);
            setOtp(new Array(4).fill(''));
            setNotValid(true);
          }
          else {
            setSaveStatusCode(res.data.statusCode);
            setMessage(res.data.message);
          }
        }
        else {
          if (res.data.message.includes("Not Activated") && otp.join('') === '' && (isActive === "true" || isActive)) {
            const dateTimeString = res.data.message.slice(14);
            const expiryTime = new Date(dateTimeString);
            const expiryTimeUTC = new Date(expiryTime.getTime() - expiryTime.getTimezoneOffset() * 60000);
            setResOtpTime(expiryTimeUTC);
            setOtpPopUp(true);
            setShowOtp(true);
          }
          else if (otp.join('') !== '' && (isActive === "true" || isActive)) {
            setOtpPopUp(false);
            setOtp(new Array(4).fill(''));
            setMessage(res.data.message);
            setSaveStatusCode(res.data.statusCode);
            updateUserRole(res.data.userId);
            setResStatus(true);
            setDrawer(false);
            ResetUserForm();
            // getClients();
            // setTimeout(ResetUserForm, 5000);
          }
          else {
            setMessage(res.data.message);
            setSaveStatusCode(res.data.statusCode);
            updateUserRole(res.data.userId);
            setResStatus(true);
            // getClients();
            setDrawer(false);
            setTimeout(ResetUserForm, 5000);
          }
        }

      })
      .catch((err) => {
        console.log(err);
        // setMessage(err.response.data.message);
        // setResStatus(false);
        // setEmailError(false);

        console.log(err);
        if (err.response.data.message.toLowerCase().includes("invalid otp")) {
          setResMsg(t("Invalid OTP"));
          setOtp(new Array(4).fill(''));
          setNotValid(true);
        }
        else {
          // getClients();
          setMessage(err.response.data.message);
          setResStatus(false);
        }
      });

  }

  async function updateUserRole(userId) {

    const user = JSON.parse(localStorage.getItem("user"));
    let clientId = new URL(window.location.href).pathname.split("/").pop();

    if (clientId === undefined || clientId === null || clientId === 0 || parseInt(clientId) === 0) {
      const roleName = [];
      const selectedRole = roleList.find((roleItem) => roleItem.id === role);
      if (selectedRole) {
        roleName.push(selectedRole.name);
      }

      const headers = {
        "Content-Type": "application/json",
      };

      let url = process.env.REACT_APP_IDENTITY_SERVER + "ids/UserRoles/AddToRoles/";

      console.log('roleList', roleList);
      console.log('role', role);
      console.log('roleName', roleName);

      axios
        .post(
          url,
          JSON.stringify({
            userId: userId,
            roles: roleName,
            isActive: JSON.parse(isActive)
          }),
          {
            headers,
          }
        )
        .then((res) => {
          handleSnackbarToggle(' User detail updated successfully', 'success');
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      const headers = {
        "Content-Type": "application/json",
      };

      let url = process.env.REACT_APP_IDENTITY_SERVER + "ids/UserRoles/AddRolesToUser/" + userId + "/" + user["user_id"] + "/" + role + "/" + isActive + "/" + 0;

      axios
        .put(url,
          JSON.stringify({}),
          {
            headers,
          }
        )
        .then((res) => {
          if (res.data) {
            getClients();
          } else {
            handleSnackbarToggle('User detail updated successfully', 'success');
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }




  const checkRegex = (email) => {
    let re =
      /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    return re.test(email);
  };



  useEffect(() => {
    ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
      return value !== password;
    });

    return () => {
      ValidatorForm.removeValidationRule('isPasswordMatch');
    };
  }, [password]);

  const calculateAdultDate = () => {
    const today = new Date();
    const adultDate = new Date(today.getFullYear() - 12, today.getMonth(), today.getDate());
    return adultDate.toISOString().split('T')[0];
  };
  const maxAdultDate = calculateAdultDate();


  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleOtpPopClose = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    setOtpPopUp(false);
    setOtp(new Array(4).fill(''));
  }

  useEffect(() => {

    const otpInvalid = localStorage.getItem("otpFail");
    if (otpInvalid && otpPopUp) {
      setDisableOtpInput(true);
      setNotValid(true);
      setResMsg(t("Too many incorrect OTP attempts. Please try after sometime"));
    }
    else {
      setDisableOtpInput(false);
      setNotValid(false);
      setResMsg("");
    }
  }, [otpPopUp])


  return (
    <div className="ticket-drawer-container">
      <div className="close-button-container">
        <CloseIcon
          className="icon-fill pointer"
          fontSize="medium"
          onClick={toggleDrawer(false)}
        ></CloseIcon>
      </div>

      <ValidatorForm onError={(errors) => console.log(errors)} onSubmit={handleRegisterUser} autoComplete="off">
        <div className="configuration-heading-container configuration-title-flex-container">
          <div>{t("Register User")}</div>
        </div>

        <div className="d-flex m-3">
          <div className="me-3">
            <TextField
              style={{ width: '100px' }}
              select
              required
              label={t("Title")}
              variant="outlined"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              autoComplete="off"
            >
              {titleOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div className="me-3">
            <TextValidator
              style={{ width: '300px' }}
              required
              label={t("First Name")}
              variant="outlined"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              autoComplete="off"
              validators={['matchRegexp:^(?! )[a-zA-Z ]*(?<! )$']}
              errorMessages={['Only alphabets and spaces (not at the beginning or end) are allowed']}
            />
          </div>
          <div>
            <TextValidator
              style={{ width: '300px' }}
              required
              label={t("Last Name")}
              variant="outlined"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              autoComplete="off"
              validators={['matchRegexp:^(?! )[a-zA-Z ]*(?<! )$']}
              errorMessages={['Only alphabets and spaces (not at the beginning or end) are allowed']}
            />
          </div>
        </div>

        <div className="d-flex m-3 bottom-line-divider">
          <div className="me-3">
            <TextValidator
              style={{ width: '250px' }}
              required
              label={t("Date of birth")}
              variant="outlined"
              size="small"
              color="secondary"
              type="date"
              value={dob}
              validators={['required']}
              errorMessages={['required']}
              InputProps={{
                inputProps: {
                  max: maxAdultDate,
                },
              }}
              onChange={(e) => setDob(e.target.value)}
              onKeyDown={(e) => e.preventDefault()}
              InputLabelProps={{
                shrink: true,
              }}
              autoComplete="off"
            />
          </div>
        </div>

        <div className="d-flex m-3">
          <div>
            <TextValidator
              style={{ width: '300px' }}
              variant="outlined"
              label={t("Contact Number")}
              value={contactNumber}
              required
              onChange={(e) => setContactNumber(e.target.value)}
              validators={['matchRegexp:^[0-9 ]+$']}
              errorMessages={[t("Only numbers are allowed")]}
              autoComplete="off"
            />
          </div>
          <div className="ms-3">
            <div autoComplete="off">
              <input type="text" style={{ display: 'none' }} />
              <input type="password" style={{ display: 'none' }} />
              <TextValidator
                style={{ width: '300px' }}
                required
                label={t("Email")}
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                validators={['required', 'isEmail']}
                errorMessages={['This field is required', 'Email is not valid']}
                autoComplete="off"
              />
            </div>
          </div>
        </div>

        <div className="d-flex m-3 bottom-line-divider">
          <div>
            <div autoComplete="off">
              <div className="d-flex align-items-center">
                <TextValidator
                  className=""
                  style={{ width: '300px' }}
                  type={showPassword ? "text" : "password"}
                  variant="outlined"
                  margin="normal"
                  size="small"
                  label={t("Password")}
                  color="primary"
                  fullWidth
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                  validators={["required"]}
                  errorMessages={[t("This Field is Required.")]}
                  autoComplete="new-password"
                  showEyeIcon={false}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleTogglePasswordVisibility}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    )
                  }}
                />
              </div>
              <PasswordStrengthBar minLength="4" password={password} />
            </div>
          </div>

          <div>
            <TextValidator
              className="ms-3"
              style={{ width: '300px' }}
              type="password"
              variant="outlined"
              margin="normal"
              size="small"
              label={t("Confirm Password")}
              color="primary"
              fullWidth
              value={confirmPassword}
              onChange={(event) => setConfirmPassword(event.target.value)}
              validators={["required"]}
              errorMessages={[t("This Field is Required.")]}
              onKeyUp={validatePassword}
              autoComplete="new-password"
            />
          </div>
        </div>

        <div className="d-flex m-3">
          <div className="me-3">
            <Autocomplete
              style={{ width: '300px' }}
              id="role-select"
              value={roleList.find((v) => checkIfUserStaff() ? v.id === role : v.roleId === role) || null}
              onChange={(event, newValue) => {
                setRole(newValue ? checkIfUserStaff() ? newValue.id : newValue.roleId : '');
              }}
              required
              options={roleList}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextValidator
                  {...params}
                  label={t("Role")}
                  required
                  variant="outlined"
                  color="secondary"
                  size="small"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                />
              )}
            />
          </div>
          <div>
            <Autocomplete
              style={{ width: '300px' }}
              id="status-select"
              value={statusOptions.find((v) => v.value === isActive) || null}
              onChange={(event, newValue) => {
                setIsActive(newValue ? newValue.value : '');
              }}
              required
              options={statusOptions}
              getOptionLabel={(option) => option.type}
              renderInput={(params) => (
                <TextValidator
                  {...params}
                  label={t("Is Active")}
                  variant="outlined"
                  color="secondary"
                  required
                  size="small"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                />
              )}
            />
          </div>
        </div>

        <div className="mt-5 ms-3">
          <Button
            variant="outlined"
            color="primary"
            size="large"
            disabled={allNotValid}
            style={{ textTransform: 'none' }}
            type="submit"
          >
            {t("Register")}
          </Button>
          <div className={saveStatusCode === 200 ? "res-succ-msg" : "res-err-msg"}>{message}</div>
        </div>
        <div className="must-container-ud cfb mt-3">
          {mustContainData.map((data) => (
            <MustContainItem key={data.label} data={data} />
          ))}
        </div>
      </ValidatorForm>
      <Dialog
        open={otpPopUp}
        // onClose={() => setOtpPopUp(false)}
        onClose={handleOtpPopClose}
      >
        <Otp
          setOtpPopUp={setOtpPopUp}
          otp={otp}
          setOtp={setOtp}
          subUserStatus={isActive}
          updateUserRole={handleRegisterUser}
          resOtpTime={resOtpTime}
          setResOtpTime={setResOtpTime}
          setExpired={setExpired}
          resMsg={resMsg}
          setResMsg={setResMsg}
          notValid={notValid}
          setNotValid={setNotValid}
          disableOtpInput={disableOtpInput}
          setDisableOtpInput={setDisableOtpInput}
          userName={email}
          retryCount={retryCount}
          setRetryCount={setRetryCount}
        />
      </Dialog>
    </div>
  );
}
