import React, { useState, useEffect } from "react";
import "./Invoice.css";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import LocationOnRoundedIcon from "@material-ui/icons/LocationOnRounded";
import LabelIcon from "@material-ui/icons/Label";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";
import EventNoteRoundedIcon from "@material-ui/icons/EventNoteRounded";
import PhoneIcon from "@material-ui/icons/Phone";
import { CircularProgress, Grid } from "@material-ui/core";
import { useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import findAirlinebyCode from "../../functions/findAirlineByCode";
import findAirportbyCode from "../../functions/findAirportByCode";
import getAirportByCode from "../../functions/getAirportByCode";
import getTotalFare from "../../functions/getTotalFare";
import getTax from "../../functions/getTax";
import getFare from "../../functions/getFare";
import getPaxTotal from "../../functions/getPaxTotal";
import getTotalFareBooking from "../../functions/getTotalFareBooking";
import html2canvas from 'html2canvas';
import { setAuthHeader } from "../../utils/axiosHeaders";
import html2pdf from 'html2pdf.js';
import { checkIfUserStaff } from "../../services/AuthService";
// import { isMobile, browserName, osName } from 'react-device-detect';
import { saveAs } from "file-saver";
import { decryptData } from "../../providers/dataEncryptDecryptor";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const downloadPDF = async (downloadRef, title, formnumber = '', margin = 0.25) => {
  var result = false;
  var pageElement = downloadRef.current;
  pageElement.classList.add("print-html");
  let opt = {
    margin: margin,
    enableLinks: true,
    filename: title + '.pdf',
    image: { type: 'jpeg', quality: 0.9 },
    html2canvas: { scale: 1 },
    jsPDF: { unit: 'in', format: 'A3', orientation: 'portrait' },
    pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
  };
  return await html2pdf().from(pageElement).set(opt).toPdf().get('pdf').then(function (pdfObject) {
    if (formnumber !== '') {
      let pdf_pages = pdfObject.internal.pages;
      for (let i = 1; i <= pdf_pages.length; i++) {
        pdfObject.setPage(i);
        pdfObject.setFontSize(10);
        pdfObject.setTextColor(16, 0, 0);
        pdfObject.text(formnumber, pdfObject.internal.pageSize.getWidth() - 2, pdfObject.internal.pageSize.getHeight() - 0.15);
      }
    }
    pdfObject.setProperties({
      title: title
    });
    pageElement.classList.remove("print-html");
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(pdfObject.output('blob'), title + ".pdf");
    } else {
      var objectUrl = pdfObject.output('bloburl');
      window.open(objectUrl, '_blank');
    }
    result = true;
    return result;
  });
}


function Invoice() {
  const [orderId, setorderId] = useState(null);
  const [bookingDetails, setbookingDetails] = useState([]);
  const [clientProfile, setclientProfile] = useState(null);
  const [invoiceDetails, setinvoiceDetails] = useState(null);
  const downloadSucesseRef = React.createRef(HTMLDivElement);
  const isStaff = checkIfUserStaff();
  const [showLoader, setShowLoader] = useState(false);
  const [extraBaggageChrgs, setExtraBaggageChrgs] = useState(0);
  const [extraMealChrgs, setExtraMealsCharge] = useState(0);
  const [seatChrgs, setSeatChrgs] = useState(0);


  let additionalServices_BaggageChrgs = 0;
  let additionalServices_MealChrgs = 0;
  let additionalServices_SeatChrgs = 0;
  const { t } = useTranslation();
  const images = require.context('../../assets', true);
  // const images = require.context('../../client_logo', true);

  //var downloadSucesseRef = React.createRef<HTMLDivElement>();
  useEffect(() => {
    if (orderId !== null) { loadBookingDetails(); }
  }, [orderId]);
  useEffect(() => {
    let temp = sessionStorage.getItem("orderId");
    if (temp) {
      decryptData(temp)
        .then((decryptedData) => {
          setorderId(decryptedData);
        })
        .catch((error) => {
          console.error(error.message);
        });
    }
  }, []);
  const location = useLocation();
  // useEffect(() => {
  //   const user = JSON.parse(localStorage.getItem("user"));
  //   const trackPageView = (path) => {
  //     window.dataLayer.push({
  //       event: 'page_view',
  //       page_path: path,
  //       user_id: user.user_id,
  //       user_email: user.email_id,
  //     });
  //   };
  //   trackPageView(location.pathname); // Track page load

  //   return () => {
  //     trackPageView(location.pathname); // Track navigation change
  //   };
  // }, [location]);
  useEffect(() => {
    if (bookingDetails !== undefined && bookingDetails.flights !== undefined && document.getElementById("bookingDetailsId") != null) {
      downloadPDF(downloadSucesseRef, "Invoice")
        .then(res => {
          console.log(res);
        });
    }
  }, [bookingDetails]);
  async function loadBookingDetails() {
    setShowLoader(true);
    setAuthHeader(localStorage.getItem('token'));
    // if (isStaff === true) {
    await axios
      .get(
        process.env.REACT_APP_CORE_URL +
        "Flights/Booking/GetBooking/v1/GetClientByGFSId/" + orderId
      )
      .then((response) => {
        //console.log(response);
        setclientProfile(response.data);
        setShowLoader(false);
      });

    setShowLoader(true);
    await axios
      .get(
        process.env.REACT_APP_CORE_URL +
        "Flights/Booking/GetBooking/v1/GetInvoice/" + orderId
      )
      .then((response) => {
        setinvoiceDetails(response.data);
        setShowLoader(false);
      });
    setShowLoader(true);
    await axios
      .get(
        process.env.REACT_APP_CORE_URL +
        "Flights/Booking/GetBooking/v1/" +
        orderId
      )
      .then((response) => {
        getAddonCal(response.data);

        setTimeout(() => {
          setbookingDetails(response.data);
          setShowLoader(false);
        }, 1000);
      });
  }

  const getAddonCal = (bookingDetails) => {
    if (
      bookingDetails &&
      bookingDetails.flights &&
      bookingDetails.flights.length > 0
    ) {
      bookingDetails.flights.map(
        (flight) =>
          flight.baggages &&
          flight.baggages.length > 0 &&
          flight.baggages.forEach((item) => {
            additionalServices_BaggageChrgs =
              additionalServices_BaggageChrgs + parseFloat(item.amount);
          })
      );
      bookingDetails.flights.map(
        (flight) =>
          flight.meals &&
          flight.meals.length > 0 &&
          flight.meals.forEach((item) => {
            additionalServices_MealChrgs =
              additionalServices_MealChrgs + parseFloat(item.amount);
          })
      );
      bookingDetails.flights.map(
        (flight) =>
          flight.seats &&
          flight.seats.length > 0 &&
          flight.seats.forEach((item) => {
            additionalServices_SeatChrgs =
              additionalServices_SeatChrgs + parseFloat(item.amount);
          })
      );
    }
    setSeatChrgs(additionalServices_SeatChrgs)
    setExtraBaggageChrgs(additionalServices_BaggageChrgs);
    setExtraMealsCharge(additionalServices_MealChrgs);
  };

  return (

    <div className="invoice-container" align="centre" ref={downloadSucesseRef}>
      <div className="invoice-header">
        <table className="invoice-header-table width100">
          <tr>
            <td className="width50">
              <div className="invoice-logo-container">
                <img
                  className="br-4 customerLogo"
                  height={50}
                  width={160}
                  //src={images(`./${selectedTicket.airline}.png`).default}
                  src={images(`./getfares.png`).default}
                // src={images(`./gf.png`).default}
                // src={images(`./getfares.png`).default}
                ></img>
              </div>
            </td>
            <td className="width50">
              {/* <div className="invoice-title-container"> */}
              <div className="invoice-title">
                <span>{t("Invoice")}</span>
              </div>
              {/* </div> */}
            </td>
          </tr>
        </table>
      </div>
      {showLoader && <div style={{ position: 'absolute', left: '45%', top: '50%' }}><CircularProgress /></div>}
      <div className="invoice-details-container">
        <table className="width100">
          <tr>
            <td>
              {clientProfile &&
                <div className="invoice-to-container">
                  <div className="invoice-to-person invoice-to-item">{clientProfile.clientName}</div>
                  <div className="invoice-to-add1 invoice-to-item">
                    {clientProfile.address1}{", "}{clientProfile.address2}
                  </div>
                  <div className="invoice-to-add2 invoice-to-item">
                    {clientProfile.city}{", "}{clientProfile.state}{", "}{clientProfile.countryCode}{"-"}{clientProfile.postalCode}
                  </div>
                  {
                    clientProfile.email !== "" ?
                      <>
                        <div className="invoice-to-add2 invoice-to-item">
                          <strong>{t("E-mail")}:</strong> {clientProfile.email}
                        </div>
                      </>
                      :
                      ''
                  }
                  <div className="invoice-to-add2 invoice-to-item">
                    {
                      clientProfile.gst !== "" ?
                        <>
                          <div><strong>{t("GSTIN")}:</strong> {clientProfile.gst}</div>
                        </>
                        :
                        ''
                    }

                    {
                      clientProfile.pan !== "" ?
                        <>
                          <div><strong>{t("PAN")}:</strong> {clientProfile.pan}</div>
                        </>
                        :
                        ''
                    }
                  </div>
                </div>
              }
            </td>
            <td>
              <div className="invoice-detail">
                <div className="invoice-item">Invoice No</div>
                <div className="invoice-separator">:</div>
                {invoiceDetails && invoiceDetails != undefined &&
                  <div className="invoice-item">{invoiceDetails.invoiceNumber}</div>
                }
              </div>
              <div className="invoice-detail">
                <div className="invoice-item">Date</div>
                <div className="invoice-separator">:</div>
                {invoiceDetails && invoiceDetails != undefined &&
                  <div className="invoice-item">{moment(invoiceDetails.createdDate).format("MM-DD-YYYY")}</div>
                }
              </div>
            </td>
          </tr>
        </table>
      </div>
      {/* <div className="invoice-body"> */}
      <div
        className="invoice-passenger-details"
        style={{ marginTop: "25px" }}
      >
        {bookingDetails !== undefined &&
          bookingDetails.flights != undefined &&
          bookingDetails.flights.length > 0 ? (
          bookingDetails.flights.map((flight) => (
            <div id="bookingDetailsId">
              <div className="itenary-passenger-details">
                <table className="itenary-passenger-table">
                  <tr className="invoice-passenger-table-header">
                    <th
                      align="left"
                      className="invoice-itenary-passenger-table-header-pl-12 invoice-header-item-font-weight s-no itenary-passenger-table-header-item-initial "
                    >
                      {t("Booking Reference")}
                    </th>
                    <th
                      align="left"
                      className="invoice-itenary-passenger-table-header-pl-12 invoice-header-item-font-weight"
                    >
                      {t("Booking Status")}
                    </th>
                    <th
                      align="left"
                      className="invoice-itenary-passenger-table-header-pl-12 invoice-header-item-font-weight itenary-passenger-table-header-item-final"
                    >
                      {t("Payment Status")}
                    </th>
                  </tr>
                  <tr className="invoice-passenger-table-item">
                    <td className="itenary-passenger-table-h-64 invoice-itenary-passenger-table-header-pl-12">
                      {bookingDetails.orderRefId}
                    </td>
                    <td className="itenary-passenger-table-h-64 invoice-itenary-passenger-table-header-pl-12">
                      {flight.currentStatus}
                    </td>
                    <td className="itenary-passenger-table-h-64 invoice-itenary-passenger-table-header-pl-12">
                      {t("Confirmed")}
                    </td>
                  </tr>
                </table>
              </div>
              <div className="itenary-container invoice-itenary-container booking-padding-top-40 invoice-booking-padding-top-40">
                <div>{t("Flight Details")}</div>
              </div>
              {flight.segGroups !== undefined &&
                flight.segGroups.length > 0 ? (
                flight.segGroups.map((segGroup) => (
                  <div className="invoice-passenger-details">
                    <div className="itenary-passenger-details">
                      <table className="itenary-passenger-table">
                        <tr className="invoice-passenger-table-header">
                          <th
                            align="left"
                            className="invoice-itenary-passenger-table-header-pl-12 invoice-header-item-font-weight s-no itenary-passenger-table-header-item-initial" style={{ minWidth: '100px' }}
                          >
                            {t("Date")}
                          </th>
                          <th
                            align="left"
                            className="invoice-itenary-passenger-table-header-pl-12 invoice-header-item-font-weight"
                            style={{ width: "250px" }}
                          >
                            {t("From")}
                          </th>
                          <th
                            align="left"
                            className="invoice-itenary-passenger-table-header-pl-12 invoice-header-item-font-weight"
                          >
                            {t("Departs")}
                          </th>
                          <th
                            align="left"
                            className="invoice-itenary-passenger-table-header-pl-12 invoice-header-item-font-weight"
                          >
                            {t("Flight")}
                          </th>
                          <th
                            align="left"
                            className="invoice-itenary-passenger-table-header-pl-12 invoice-header-item-font-weight"
                            style={{ width: "250px" }}
                          >
                            {t("To")}
                          </th>
                          <th
                            align="left"
                            className="invoice-itenary-passenger-table-header-pl-12 invoice-header-item-font-weight itenary-passenger-table-header-item-final"
                          >
                            {t("Arrives")}
                          </th>
                        </tr>
                        {segGroup.segments !== undefined &&
                          segGroup.segments.length > 0 ? (
                          segGroup.segments.map((segment) => (
                            <tr className="invoice-passenger-table-item">
                              <td className="itenary-passenger-table-h-64 invoice-itenary-passenger-table-header-pl-12">
                                {moment(segment.departureOn).format("Do MMM YY")}
                              </td>
                              <td className="itenary-passenger-table-h-64 invoice-itenary-passenger-table-header-pl-12">
                                {getAirportByCode(segment.origin)} - {segment.origin}
                              </td>
                              <td className="itenary-passenger-table-h-64 invoice-itenary-passenger-table-header-pl-12">
                                {moment(segment.departureOn).format("HH:mm")}
                              </td>
                              <td className="itenary-passenger-table-h-64 invoice-itenary-passenger-table-header-pl-12">
                                {segment.mrkAirline}-{segment.flightNum}
                              </td>
                              <td className="itenary-passenger-table-h-64 invoice-itenary-passenger-table-header-pl-12">
                                {getAirportByCode(segment.destination)} - {segment.destination}
                              </td>
                              <td className="itenary-passenger-table-h-64 invoice-itenary-passenger-table-header-pl-12">
                                {moment(segment.arrivalOn).format("HH:mm")}
                              </td>
                            </tr>
                          ))
                        ) : (
                          // <tr></tr>
                          ''
                        )}
                      </table>
                    </div>
                  </div>
                ))
              ) : (
                // <div></div>
                ''
              )}
              <div className="itenary-container invoice-itenary-container booking-padding-top-40 invoice-booking-padding-top-40">
                <div>{t("Passenger Details")}</div>
              </div>
              <div className="invoice-passenger-details">
                <div className="itenary-passenger-details">
                  <table className="itenary-passenger-table">
                    <tr className="invoice-passenger-table-header" style={{ textAlign1: 'left !important' }}>
                      <th
                        align="left"
                        className="invoice-itenary-passenger-table-header-pl-12 invoice-header-item-font-weight "
                      >
                        {t("Passenger(s)")}
                      </th>
                      <th
                        align="left"
                        className="invoice-itenary-passenger-table-header-pl-12 invoice-header-item-font-weight"
                      >
                        {t("Gender")}
                      </th>
                      <th
                        align="left"
                        className="invoice-itenary-passenger-table-header-pl-12 invoice-header-item-font-weight"
                      >
                        {t("Date of Birth")}
                      </th>
                      <th
                        align="left"
                        className="invoice-itenary-passenger-table-header-pl-12 invoice-header-item-font-weight"
                      >
                        {t("Ticket(s)")}
                      </th>
                      <th
                        align="left"
                        className="invoice-itenary-passenger-table-header-pl-12 invoice-header-item-font-weight"
                      >
                        {t("Paid Baggage")}
                      </th>
                      <th
                        align="left"
                        className="invoice-itenary-passenger-table-header-pl-12 invoice-header-item-font-weight"
                      >
                        {t("Paid Meal")}
                      </th>
                      <th
                        align="left"
                        className="invoice-itenary-passenger-table-header-pl-12 invoice-header-item-font-weight itenary-passenger-table-header-item-final"
                      >
                        {t("Paid Seat")}
                      </th>
                    </tr>
                    {flight.passengers !== undefined &&
                      flight.passengers.length > 0 ? (
                      flight.passengers.sort(function (a, b) {
                        if (a.paxType < b.paxType) { return -1; }
                        if (a.paxType > b.paxType) { return 1; }
                        return 0;
                      }).map((passenger, index) => (
                        <tr className="invoice-passenger-table-item">
                          <td className="itenary-passenger-table-h-64 invoice-itenary-passenger-table-header-pl-12">
                            {passenger.title} {passenger.firstName}{" "}{passenger.lastName}
                          </td>
                          <td className="itenary-passenger-table-h-64 invoice-itenary-passenger-table-header-pl-12">
                            {passenger.genderType}
                          </td>
                          <td className="itenary-passenger-table-h-64 invoice-itenary-passenger-table-header-pl-12">
                            {moment(passenger.dob).format("Do MMMM YYYY")}
                          </td>
                          <td className="itenary-passenger-table-h-64 invoice-itenary-passenger-table-header-pl-12">
                            {passenger.ticketNumber}
                          </td>
                          <td className="itenary-passenger-table-h-64 invoice-itenary-passenger-table-header-pl-12">
                            {flight.baggages?.filter(
                              (item) =>
                                item.paxName ===
                                passenger.firstName +
                                " " +
                                passenger.lastName
                            ) ? (
                              <td className="itenary-passenger-table-h-64 invoice-itenary-passenger-table-header-pl-12">
                                {flight.baggages?.filter(
                                  (item) =>
                                    item?.paxName ===
                                    passenger.firstName +
                                    " " +
                                    passenger.lastName
                                ).length > 0 &&
                                  flight.baggages
                                    ?.filter(
                                      (item) =>
                                        item?.paxName ===
                                        passenger.firstName +
                                        " " +
                                        passenger.lastName
                                    )
                                    .map((bag, index) => (
                                      <>
                                        {bag.checkInBag}{" "}
                                        {flight.currency} {"  "}{" "}
                                        {bag.amount}
                                        {<br></br>}
                                        {flight.baggages?.filter(
                                          (item) =>
                                            item.paxName ===
                                            passenger.firstName +
                                            " " +
                                            passenger.lastName
                                        ).length -
                                          1 <
                                          index
                                          ? "\n"
                                          : ""}{" "}
                                      </>
                                    ))}
                              </td>
                            ) : (
                              <td>NA</td>
                            )}
                          </td>
                          <td className="itenary-passenger-table-h-64 invoice-itenary-passenger-table-header-pl-12">
                            {/* {passenger.mealPref === "" ? "NA" : passenger.mealPref} */}
                            {flight.meals.length > 0 ? (
                              <>
                                {flight.meals?.filter(
                                  (item) =>
                                    item.paxName ===
                                    passenger.firstName +
                                    " " +
                                    passenger.lastName
                                ).length > 0 ? (
                                  <td className="itenary-passenger-table-h-64 invoice-itenary-passenger-table-header-pl-12">
                                    {flight.meals?.filter(
                                      (item) =>
                                        item?.paxName ===
                                        passenger.firstName +
                                        " " +
                                        passenger.lastName
                                    ).length > 0 &&
                                      flight.meals
                                        ?.filter(
                                          (item) =>
                                            item?.paxName ===
                                            passenger.firstName +
                                            " " +
                                            passenger.lastName
                                        )
                                        .map((meal, index) => (
                                          <>
                                            {meal.description}{" "}
                                            {flight.currency} {"  "}{" "}
                                            {meal.amount}
                                            {<br></br>}
                                            {flight.meals?.filter(
                                              (item) =>
                                                item.paxName ===
                                                passenger.firstName +
                                                " " +
                                                passenger.lastName
                                            ).length -
                                              1 <
                                              index
                                              ? "\n"
                                              : ""}{" "}
                                          </>
                                        ))}
                                  </td>
                                ) : (
                                  <td align="center">
                                    NA
                                  </td>
                                )}
                              </>
                            ) : null}
                          </td>
                          <td className="itenary-passenger-table-h-64 invoice-itenary-passenger-table-header-pl-12">
                            {flight && flight?.seats[index] && flight?.seats[index]?.seatNo || '-'}
                          </td>

                        </tr>
                      ))
                    ) : (
                      // <tr></tr>
                      ''
                    )}
                  </table>
                </div>
              </div>
              <div className="itenary-container invoice-itenary-container booking-padding-top-40 invoice-booking-padding-top-40">
                <div>{t("Fare Details")}</div>
              </div>
              <div className="invoice-passenger-details">
                <div className="itenary-passenger-details">
                  <table className="itenary-passenger-table">
                    <tr className="invoice-passenger-table-header">
                      <th
                        align="left"
                        className="width20 invoice-itenary-passenger-table-header-pl-12 invoice-header-item-font-weight s-no itenary-passenger-table-header-item-initial "
                      >
                        {t("Pax")}
                      </th>
                      <th
                        align="left"
                        className="width20 invoice-itenary-passenger-table-header-pl-12 invoice-header-item-font-weight"
                      >
                        {t("Base Fare")}
                      </th>
                      <th
                        align="left"
                        className="width20 invoice-itenary-passenger-table-header-pl-12 invoice-header-item-font-weight"
                      >
                        {t("Tax")}
                      </th>
                      <th
                        align="left"
                        className="width20 invoice-itenary-passenger-table-header-pl-12 invoice-header-item-font-weight"
                      >
                        {t("GST")}
                      </th>
                      <th
                        align="left"
                        className="width20 invoice-itenary-passenger-table-header-pl-12 invoice-header-item-font-weight itenary-passenger-table-header-item-final"
                      >
                        {t("Total")}
                      </th>
                    </tr>
                    {flight.flightFares !== undefined &&
                      flight.flightFares.length > 0 ? (
                      flight.flightFares.sort(function (a, b) {
                        if (a.paxType < b.paxType) { return -1; }
                        if (a.paxType > b.paxType) { return 1; }
                        return 0;
                      }).map((fare) => (
                        <tr className="invoice-passenger-table-item">
                          <td className=" width20 itenary-passenger-table-h-64 invoice-itenary-passenger-table-header-pl-12">
                            {fare.paxType == "ADT" ? "Adult" :
                              fare.paxType == "CHD" ? "Child" :
                                fare.paxType == "INF" ? "Infant" : "Adult"}
                          </td>
                          <td className="width20 itenary-passenger-table-h-64 invoice-itenary-passenger-table-header-pl-12">
                            {fare.paxType == "ADT" ? flight.adultCount + " x " + fare.baseFare :
                              fare.paxType == "CHD" ? flight.childCount + " x " + fare.baseFare :
                                fare.paxType == "INF" ? flight.infantCount + " x " + fare.baseFare : "0"}
                          </td>
                          <td className="width20 itenary-passenger-table-h-64 invoice-itenary-passenger-table-header-pl-12">
                            {fare.paxType == "ADT" ? flight.adultCount + " x " + getTax(fare).toFixed(2) :
                              fare.paxType == "CHD" ? flight.childCount + " x " + getTax(fare).toFixed(2) :
                                fare.paxType == "INF" ? flight.infantCount + " x " + getTax(fare).toFixed(2) : "0"}
                          </td>
                          <td className="width20 itenary-passenger-table-h-64 invoice-itenary-passenger-table-header-pl-12">
                            {fare.paxType == "ADT" ? flight.adultCount + " x 0" :
                              fare.paxType == "CHD" ? flight.childCount + " x 0" :
                                fare.paxType == "INF" ? flight.infantCount + " x 0  " : "0"}
                          </td>
                          <td className="width20 itenary-passenger-table-h-64 invoice-itenary-passenger-table-header-pl-12">
                            {fare.paxType == "ADT" ? (getPaxTotal(fare, flight.adultCount).toFixed(2)) :
                              fare.paxType == "CHD" ? (getPaxTotal(fare, flight.childCount).toFixed(2)) :
                                fare.paxType == "INF" ? (getPaxTotal(fare, flight.infantCount).toFixed(2)) : 0}
                          </td>
                        </tr>
                      ))
                    ) : (
                      // <tr></tr>
                      ''
                    )}
                    {extraBaggageChrgs > 0 || extraMealChrgs > 0 || seatChrgs > 0 ? (
                      <>

                        <tr className="itenary-passenger-table-item">
                          <td className="width20 itenary-passenger-table-h-64 invoice-itenary-passenger-table-header-pl-12 invoice-baggage-price-label" align="right"></td>
                          <td className="width20 itenary-passenger-table-h-64 invoice-itenary-passenger-table-header-pl-12 invoice-baggage-price-label" align="right"></td>
                          <td className="width20 itenary-passenger-table-h-64 invoice-itenary-passenger-table-header-pl-12 invoice-baggage-price-label" align="right"></td>
                          <td className="width25 itenary-passenger-table-h-64 invoice-itenary-passenger-table-header-pl-12 invoice-baggage-price-label" align="right">Additional Service Charges</td>
                          <td className="itenary-passenger-table-h-64 invoice-itenary-passenger-table-header-pl-12 invoice-baggage-price-txt">{flight.currency} {(extraBaggageChrgs + extraMealChrgs + seatChrgs).toFixed(2)}</td>
                        </tr>


                        <tr className="itenary-passenger-table-item">
                          <td className="width20 itenary-passenger-table-h-64 invoice-itenary-passenger-table-header-pl-12 invoice-total-price-label" align="right"></td>
                          <td className="width20 itenary-passenger-table-h-64 invoice-itenary-passenger-table-header-pl-12 invoice-total-price-label" align="right"></td>
                          <td className="width20 itenary-passenger-table-h-64 invoice-itenary-passenger-table-header-pl-12 invoice-total-price-label" align="right"></td>
                          <td className="width20 itenary-passenger-table-h-64 invoice-itenary-passenger-table-header-pl-12 invoice-total-price-label" align="right">Total</td>
                          <td className="itenary-passenger-table-h-64 invoice-itenary-passenger-table-header-pl-12 invoice-total-price-txt">{flight.currency} {(
                            getTotalFareBooking(flight.flightFares, flight.adultCount, flight.childCount, flight.infantCount) + extraBaggageChrgs + extraMealChrgs + seatChrgs
                          ).toFixed(2)}</td>
                        </tr>
                      </>
                    ) : (
                      <>
                        <tr className="itenary-passenger-table-item">
                          <td className="width20 itenary-passenger-table-h-64 invoice-itenary-passenger-table-header-pl-12 invoice-total-price-label" align="right"></td>
                          <td className="width20 itenary-passenger-table-h-64 invoice-itenary-passenger-table-header-pl-12 invoice-total-price-label" align="right"></td>
                          <td className="width20 itenary-passenger-table-h-64 invoice-itenary-passenger-table-header-pl-12 invoice-total-price-label" align="right"></td>
                          <td className="width20 itenary-passenger-table-h-64 invoice-itenary-passenger-table-header-pl-12 invoice-total-price-label" align="right">Total</td>
                          <td className="itenary-passenger-table-h-64 invoice-itenary-passenger-table-header-pl-12 invoice-total-price-txt">{flight.currency} {getTotalFareBooking(flight.flightFares, flight.adultCount, flight.childCount, flight.infantCount).toFixed(2)}</td>
                        </tr>
                      </>
                    )}

                  </table>
                </div>
              </div>

              <div className="itenary-container booking-padding-top-64">
                <div>{t("Baggage Details")}</div>
              </div>
              <div className="baggage-details">
                {flight.baggages !== undefined &&
                  flight.baggages.length > 0 ? (
                  flight.baggages.sort(function (a, b) {
                    if (a.paxType < b.paxType) { return -1; }
                    if (a.paxType > b.paxType) { return 1; }
                    return 0;
                  }).map((bagg) => (
                    <div className="baggage-details-container-invoice row">
                      <div className="col-2">
                        <div className="baggage-detail-text">
                          {t("Pax")}: {bagg.paxType}
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="baggage-detail-text">
                          {t("Check In Bag")}: {bagg.checkInBag ? bagg.checkInBag : "NIL"}
                        </div>

                      </div>
                      <div className="col-4">

                        <div className="baggage-detail-text">
                          {t("Cabin Bag")}: {bagg.cabinBag ? bagg.cabinBag : 'NIL'}
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="baggage-detail-text">
                          {t("CityPair")}: {bagg.cityPair}
                        </div>
                      </div>
                    </div>
                  )))
                  : (
                    // <div></div>
                    ''
                  )}
              </div>

              {flight.meals && flight.meals.length > 0 &&
                <><div className="itenary-container booking-padding-top-64">
                  <div>{t("Meal Details")}</div>
                </div><div className="baggage-details">
                    {flight.meals !== undefined &&
                      flight.meals.length > 0 ? (
                      flight.meals.sort(function (a, b) {
                        if (a.paxType < b.paxType) { return -1; }
                        if (a.paxType > b.paxType) { return 1; }
                        return 0;
                      }).map((meal) => (
                        <div className="baggage-details-container-invoice row">
                          <div className="col-2">
                            <div className="baggage-detail-text">
                              {t("Pax")}: {meal.paxType}
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="baggage-detail-text">
                              {t("Description")}: {meal.description ? meal.description : "NIL"}
                            </div>

                          </div>

                          <div className="col-4">
                            <div className="baggage-detail-text">
                              {t("CityPair")}: {meal.cityPair}
                            </div>
                          </div>
                        </div>
                      )))
                      : (
                        // <div></div>
                        ''
                      )}
                  </div>
                </>
              }

              {flight.seats && flight.seats.length > 0 &&
                <><div className="itenary-container booking-padding-top-64">
                  <div>{t("Seat Details")}</div>
                </div><div className="baggage-details">
                    {flight.seats !== undefined &&
                      flight.seats.length > 0 ? (
                      flight.seats.sort(function (a, b) {
                        if (a.paxType < b.paxType) { return -1; }
                        if (a.paxType > b.paxType) { return 1; }
                        return 0;
                      }).map((seat) => (
                        <div className="baggage-details-container-invoice row">
                          <div className="col-2">
                            <div className="baggage-detail-text">
                              {t("Pax")}: {seat.paxType}
                            </div>
                          </div>
                          <div className="col-2">
                            <div className="baggage-detail-text">
                              {t("Seat No")}: {seat.seatNo ? seat.seatNo : "NIL"}
                            </div>

                          </div>
                          <div className="col-4">
                            <div className="baggage-detail-text">
                              {t("Aircraft Type")}: {seat.airCraftType}
                            </div>
                          </div>
                          <div className="col-2">
                            <div className="baggage-detail-text">
                              {t("CityPair")}: {seat.cityPair}
                            </div>
                          </div>
                          <div className="col-2">
                            <div className="baggage-detail-text">
                              {t("Amount")}: {seat.amount.toFixed(2)}
                            </div>
                          </div>
                        </div>
                      )))
                      : (
                        // <div></div>
                        ''
                      )}
                  </div>
                </>
              }


            </div>
          ))
        ) : (
          // <div className="itenary-passenger-details">
          // </div>
          ''
        )}
      </div>

      <div className="itenary-container invoice-itenary-container booking-padding-top-64">
        <div>{t("Contact Details")}</div>
      </div>
      <div className="contact-details">
        <div className="contact-details-container invoice-contact-details-container">
          <div className="contact-detail-flex">
            <div className="contact-detail-item">
              <div className="contact-detail-icon contact-detail-opacity">
                <LocationOnRoundedIcon fontSize="small"></LocationOnRoundedIcon>
              </div>
              <div className="contact-detail-text">MUMBAI MH</div>
            </div>
          </div>
          <div className="contact-detail-flex">
            <div className="contact-detail-item">
              <div className="contact-detail-icon contact-detail-opacity">
                <LabelIcon fontSize="small"></LabelIcon>
              </div>
              <div className="contact-detail-text">www.GetFares.com</div>
            </div>
          </div>
          <div className="contact-detail-flex">
            <div className="contact-detail-item">
              <div className="contact-detail-icon contact-detail-opacity">
                <AlternateEmailIcon fontSize="small"></AlternateEmailIcon>
              </div>
              <div className="contact-detail-text">
                info@akbaroffshore.com
              </div>
            </div>
          </div>
          <div className="contact-detail-flex">
            <div className="contact-detail-item">
              <div className="contact-detail-icon contact-detail-opacity">
                <PhoneIcon fontSize="small"></PhoneIcon>
              </div>
              <div className="contact-detail-text min-width-101">
                +91 22 40003434
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="itenary-container invoice-itenary-container booking-padding-top-64">
        <div>{t("More Details")}</div>
      </div>
      <div className="more-details">
        <div className="more-details-container">
          <div className="more-detail-item">
            <div className="more-detail-item-icon invoice-more-detail-item-icon">
              <EventNoteRoundedIcon fontSize="medium"></EventNoteRoundedIcon>
            </div>
            <div style={{ display: "flex" }}>
              <div>
                <div className="invoice-to-person invoice-to-item">
                  Akbar Offshore{" "}
                </div>
                <Grid container spacing={4}>
                  <Grid item xs={6}>
                    <div>
                      <div className="invoice-to-add2 invoice-to-item">
                        178, Kavarana Mansion , Dr Baba Saheb Ambedkar Rd
                      </div>
                      <div className="invoice-to-add2 invoice-to-item">
                        Dadar East, Mumbai, Maharashtra 400014
                      </div>
                      <div className="invoice-to-add2 invoice-to-item">
                        info@AkbarOffshore.com
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="invoice-to-add2 invoice-to-item px-4">
                      <div className="invoice-to-add2 invoice-to-item"><strong>{t("CIN")}:</strong> U63030MH2019PTC323160</div>
                      <div className="invoice-to-add2 invoice-to-item"><strong>{t("GSTIN")}:</strong> 27AASCA0975B1ZM</div>
                      <div className="invoice-to-add2 invoice-to-item"><strong>{t("PAN")}:</strong> AASCA0975B</div>
                    </div>
                  </Grid>
                </Grid>

              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>


  );
}
export default Invoice;
