import React, { useEffect, Fragment } from "react";
import { KeyboardDatePicker } from "@material-ui/pickers";
import "./SearchBar.css";
import { Button, TextField, InputAdornment } from "@material-ui/core";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import { formatDate } from "../../../services/dateFormatter";
import { useTranslation } from "react-i18next";
export default function SearchBar(props) {
  const { t } = useTranslation();
  const onSearchHandler = () => {
    props.setCurrentPage(1);
    props.postSearchHandler(props.q, props.inputDateFrom, props.inputDateTo, false);
  };
  const onChangeHandler = (event) => {
    props.setQ((q) => event.target.value);
  };

  useEffect(() => {
    console.log("Q", props.q);
  }, [props.q]);

  const clearSearch = () => {
    document.getElementById("search-box").value = "";
    props.setPageNav(false);
    props.setDateFrom((DateFrom) => null);
    props.setinputDateFrom((inputDateTo) => null);
    props.setDateTo((DateFrom) => null);
    props.setinputDateTo((inputDateTo) => null);
    props.setFilters((filters) => []);
    props.setQ((q) => "");
  };

  useEffect(() => {
    if (
      props.filters.length == 0 &&
      props.isPageNav == false &&
      props.initialLoad == true
    ) {
      (props.q !== "") ? props.postSearchHandler(props.q, null, null, false) : props.postSearchHandler("", null, null, false);
    }
  }, [props.filters]);


  return (
    <div className="trans-client-container">
      <div style={{ display: 'flex', alignItems: 'center' }} className="trans-client-container-label trans-client-container-labelLeft">
        {t("Search")}
      </div>
      <div className="suggestions trans-client-container-item2 debit-transaction-panel">
        <TextField
          variant="filled"
          autoComplete="off"
          type="text"
          id="search-box"
          style={{ width: "300px" }}
          placeholder={
            props?.data?.includes("ClientName")
              ? t("ClientName, ClientId, VendorName, PCC")
              : t("What are you looking for?")
          }
          onChange={onChangeHandler}
          value={props.q}
          defaultValue={props.q}
          startAdornment={
            <InputAdornment position="start">
              <SearchOutlinedIcon />
            </InputAdornment>
          }
          required
        />
      </div>
      <div className="trans-client-container-label">{t("From Date")}</div>
      <div className="trans-client-container-item2">
        <Fragment>
          <KeyboardDatePicker
            maxDate={new Date()}
            id="DateFrom"
            autoOk
            margin="normal"
            variant="filled"
            format="dd/MM/yyyy"
            size="small"
            value={props.DateFrom !== undefined ? props.DateFrom : null}
            inputValue={props.DateFrom !== undefined ? props.DateFrom : null}
            InputAdornmentProps={{
              position: "start",
              color: "primary",
            }}
            onChange={(date) => {
              props.setDateFrom(date ? formatDate(date) : null);
              props.setinputDateFrom(date ? date : null);
            }}
            required
          />
        </Fragment>
      </div>
      <div className="trans-client-container-label">{t("To Date")}</div>
      <div className="trans-client-container-item2">
        <Fragment>
          <KeyboardDatePicker
            maxDate={new Date()}
            id="DateTo"
            autoOk
            margin="normal"
            variant="filled"
            format="dd/MM/yyyy"
            size="small"
            value={props.DateTo !== undefined ? props.DateTo : null}
            inputValue={props.DateTo !== undefined ? props.DateTo : null}
            InputAdornmentProps={{
              position: "start",
              color: "primary",
            }}
            onChange={(date) => {
              props.setDateTo(date ? formatDate(date) : null);
              props.setinputDateTo(date ? date : null);
            }}
            required
          />
        </Fragment>
      </div>
      {props.flag === "OfflineImportReport" ? (
        <Button
          className="trans-client-container-search-button"
          onClick={props.myqueueExportExcel}
          disabled={!props.DateFrom && !props.DateTo && !props.q}
        >
          {t("Export Data")}
        </Button>
      ) : (
        <Button
          className="trans-client-container-button"
          style={{ marginLeft: "5px" }}
          color="primary"
          type="submit"
          onClick={onSearchHandler}
          variant="contained"
        >
          {t("Search")}
        </Button>
      )}
      <Button
        className="trans-client-container-button"
        style={{
          marginLeft: "5px",
          color: "white",
          backgroundColor: "#9f155a",
        }}
        type="submit"
        onClick={clearSearch}
        variant="contained"
      >
        {t("Clear")}
      </Button>
    </div>
  );
}
