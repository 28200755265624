import React, { useState, useEffect } from "react";
import "./Eticket.css";
import { CircularProgress } from "@material-ui/core";
import axios from "axios";
import moment from "moment";
import { setAuthHeader } from "../../utils/axiosHeaders";
import html2pdf from "html2pdf.js";
import {
  ValidatorForm,
  TextValidator,
} from "react-material-ui-form-validator";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tickets from "./Tickets";
import Link from "@mui/material/Link";
import { decryptData } from "../../providers/dataEncryptDecryptor";
import TicketQRCode from "../../providers/QRTicketDataProvider/QRTicketDataProvider";
import { Flight } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

const downloadPDF = async (
  downloadRef,
  title,
  formnumber = "",
  margin = 0.25
) => {
  var result = false;
  var pageElement = downloadRef.current;
  let opt = {
    margin: [15, 15, 15, 15],
    jsPDF: { format: "A4", orientation: "portrait" },
  };
  return await html2pdf()
    .from(pageElement)
    .set(opt)
    .toPdf()
    .get("pdf")
    .then(function (pdfObject) {
      if (formnumber !== "") {
        let pdf_pages = pdfObject.internal.pages;
        for (let i = 1; i <= pdf_pages.length; i++) {
          pdfObject.setPage(i);
          pdfObject.setFontSize(10);
          pdfObject.setTextColor(16, 0, 0);
          pdfObject.text(
            formnumber,
            pdfObject.internal.pageSize.getWidth() - 2,
            pdfObject.internal.pageSize.getHeight() - 0.15
          );
        }
      }
      pdfObject.setProperties({
        title: title,
      });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(
          pdfObject.output("blob"),
          title + ".pdf"
        );
      } else {
        var objectUrl = pdfObject.output("bloburl");
        window.open(objectUrl, "_blank");
      }
      result = true;
      return result;
    });
};

function Eticket() {
  const [bookingDetails, setBookingDetails] = useState([]);
  const [clientProfile, setClientProfile] = useState(null);
  const [displayLogo, setDisplayLogo] = useState(null);
  const downloadSucesseRef = React.createRef(HTMLDivElement);
  const images = require.context("../../client_logo", true);
  const [orderId, setOrderId] = useState("");
  const fareDecision = localStorage.getItem("fareType");
  const [INFMarkup, setINFMarkup] = useState(0);
  const [childMarkup, setChildMarkup] = useState(0);
  const [adultMarkup, setAdultMarkup] = useState(0);
  const [showLoader, setShowLoader] = useState(false);
  const [hover, setHover] = useState(false);
  const [hideMarkup, setHideMarkup] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {

    fixCapitalsNode(document.getElementById("importantNote"));
  }, [bookingDetails]);

  function downloadTicket() {
    setHideMarkup(true);
    if (
      bookingDetails !== undefined &&
      bookingDetails.flights !== undefined &&
      document.getElementById("bookingDetailsId") !== null
    ) {
      downloadPDF(downloadSucesseRef, "Eticket").then((res) => {
        setHideMarkup(false);
      });
    }
  }

  function setAdultValues(fare, Count, values) {
    let value = values ? parseInt(values) : 0;
    setAdultMarkup(value);
  }
  function setChildValues(fare, Count, values) {
    let value = values ? parseInt(values) : 0;
    setChildMarkup(value);
  }
  function setINFvalues(fare, Count, values) {
    let value = values ? parseInt(values) : 0;
    setINFMarkup(value);
  }

  useEffect(() => {
    if (orderId) {
      loadClientDetails();
      loadBookingDetails();
    }
  }, [orderId]);
  useEffect(() => {
    let temp = sessionStorage.getItem("orderId");
    if (temp) {
      decryptData(temp)
        .then((decryptedData) => {
          setOrderId(decryptedData);
        })
        .catch((error) => {
          console.error(error.message);
        });
    }
  }, []);

  async function loadClientDetails() {
    setAuthHeader(localStorage.getItem("token"));
    await axios
      .get(
        process.env.REACT_APP_CORE_URL +
        "Flights/Booking/GetBooking/v1/GetClientByGFSId/" +
        orderId
      )
      .then((response) => {
        setClientProfile(response.data);
        loadImage(response.data.id);
      });
  }

  async function loadBookingDetails() {
    setShowLoader(true);
    setAuthHeader(localStorage.getItem("token"));
    await axios
      .get(
        process.env.REACT_APP_CORE_URL +
        "Flights/Booking/GetBooking/v1/NewView/" +
        orderId
      )
      .then((response) => {
        setTimeout(() => {
          setBookingDetails(response.data);
          setShowLoader(false);
        }, 1000);
      });
  }

  function loadImage(id) {
    let url = "https://images.getfares.com/client_logo/" + id + ".png";
    let img = new Image();

    img.onload = function () {
      setDisplayLogo(`./` + id + `.png`);
    };

    img.onerror = function () {
      setDisplayLogo(null);
    };

    img.src = url;
  }

  const onHover = () => {
    setHover(true);
  };

  const onLeave = () => {
    setHover(false);
  };

  const showRow = bookingDetails?.flights?.length > 0 ? bookingDetails?.flights[0]?.clientFare?.some(
    (fare) => fare.baggagefare > 0 || fare.mealFare > 0 || fare.seatFare > 0
  ) : false;
  const clientTotalFare =
    bookingDetails?.flights?.length > 0 ? bookingDetails?.flights[0]?.clientFare?.reduce(
      (total, item) =>
        total + (item?.clientTotal * item?.paxCount || 0),
      0
    )
      .toFixed(2) : 0;
  const additionalServiceTotalFare =
    bookingDetails?.flights?.length > 0 ? bookingDetails?.flights[0]?.clientFare?.reduce(
      (total, item) =>
        total + (item?.baggagefare + item?.mealFare + item?.seatFare || 0),
      0
    )
      .toFixed(2) : 0;
  const paxTypeLabels = {
    ADT: 'Adult',
    CHD: 'Child',
    INF: 'Infant',
  };
  const sortFares = (fares) => {
    return fares.sort((a, b) => {
      if (a.paxType < b.paxType) return -1;
      if (a.paxType > b.paxType) return 1;
      return 0;
    });
  };
  function getPassengerFare(fare, flight, includePassThroughMarkup = true) {
    let passengerCount, baseFare, markup;
    const passThroughMarkup = fare?.passThroughMarkup ? fare.passThroughMarkup : 0;

    switch (fare.paxType) {
      case "ADT":
        passengerCount = flight.adultCount;
        baseFare = fare.baseFare + adultMarkup;
        markup = includePassThroughMarkup ? passThroughMarkup : 0;
        break;

      case "CHD":
        passengerCount = flight.childCount;
        baseFare = fare.baseFare + childMarkup;
        markup = includePassThroughMarkup ? passThroughMarkup : 0;
        break;

      case "INF":
        passengerCount = flight.infantCount;
        baseFare = fare.baseFare + INFMarkup;
        markup = 0;
        break;

      default:
        passengerCount = 0;
        baseFare = 0;
        markup = 0;
    }

    return passengerCount + " x " + (baseFare + markup);
  }
  function getMarkupValue(paxType) {
    switch (paxType) {
      case "ADT":
        return adultMarkup;
      case "CHD":
        return childMarkup;
      default:
        return INFMarkup;
    }
  }
  const getMarkupTotalValue = (fare, paxType) => {
    switch (paxType) {
      case "ADT":
        return fare.clientTotal + adultMarkup;
      case "CHD":
        return fare.clientTotal + childMarkup;
      default:
        return fare.clientTotal + INFMarkup;
    }
  };
  const getBaggageForPassenger = (baggages, passenger) =>
    baggages.filter((item) =>
      item.paxName === passenger.firstName + " " + passenger.lastName
    );
  const filterMeals = (flight, passenger) => {
    return flight.meals?.filter((item) =>
      item.paxName === passenger.firstName + " " + passenger.lastName
    );
  };
  return (
    <>
      <div className="marginLR15">
        <div
          className="eticket-container"
          ref={downloadSucesseRef}
        >
          <div className="eticket-header">
            <TableContainer>
              <Table className="eticket-header-table width100">
                <TableBody>
                  {" "}
                  <TableRow>
                    <TableCell className="width60">
                      {clientProfile && (
                        <Table className="clientDetailsTable">
                          <TableBody>
                            <TableRow>
                              <TableCell>{t("Name")}</TableCell>
                              <TableCell> {clientProfile.clientName}</TableCell>
                            </TableRow>
                            <TableRow>
                              {" "}
                              <TableCell>{t("Address")}</TableCell>
                              <TableCell>
                                {clientProfile.address1}
                                {", "}
                                {clientProfile.address2}
                                {", "} {clientProfile.city}
                                {", "}
                                {clientProfile.state}
                                {", "}
                                {clientProfile.countryCode}
                                {"-"}
                                {clientProfile.postalCode}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              {" "}
                              <TableCell>{t("Contact")}</TableCell>
                              <TableCell>{clientProfile.phoneNumber}</TableCell>
                            </TableRow>
                            <TableRow>
                              {" "}
                              <TableCell>{t("Email")}</TableCell>
                              <TableCell>{clientProfile.email}</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      )}
                    </TableCell>
                    <TableCell className="width40">
                      <div className="eticket-logo-container">
                        {displayLogo ? (
                          <img
                            className="br-4 customerLogo"
                            height={50}
                            width={160}
                            alt="client logo"
                            src={images(displayLogo).default}
                          />
                        ) : (

                          ""
                        )}

                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          {showLoader && (
            <div style={{ position: "absolute", left: "45%", top: "50%" }}>
              <CircularProgress />
            </div>
          )}
          <div className="eticket-body">
            <div className="eticket-passenger-details">
              {bookingDetails !== undefined &&
                bookingDetails.flights !== undefined &&
                bookingDetails.flights.length > 0 ? (
                bookingDetails.flights.map((flight) => (
                  <>
                    <TableContainer>
                      {" "}
                      <Table className="itenary-passenger-table eticket-header-table">
                        <TableBody>
                          <TableRow className="eticket-passenger-table-header">
                            <TableCell
                              align="center"
                              className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12"
                            >
                              {t("Date of Booking")}:<span className="bold pl-5 fs16 ">
                                {moment(bookingDetails.createdOn).format(
                                  "Do MMM  YYYY"
                                )}
                              </span>
                            </TableCell>
                            <TableCell
                              align="center"
                              className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12"
                            >
                              {t("Booking Status")}:<span
                                className={`bold pl-5 fs16 itenary-confirmtaion-tag eTicket${flight.currentStatus}`}
                              >
                                {flight.currentStatus}
                              </span>
                            </TableCell>
                            <TableCell
                              align="center"
                              className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12"
                            >
                              {t("Booking Reference")}:{" "}
                              <span className="bold pl-5 fs16 ">
                                {bookingDetails.orderRefId}
                              </span>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <div className="eticketFlightSection">
                      <div className="itenary-container eticket-itenary-container booking-padding-top-40 eticket-booking-padding-top-40">
                        <div>{t("Flight Details")}</div>
                      </div>
                      <Tickets
                        result={flight}
                        baggages={flight.baggages}
                        segGroups={flight.segGroups}
                        source={bookingDetails.bookingSource}
                      ></Tickets>
                    </div>

                    <div
                      id="bookingDetailsId"
                      className="eticket-itenary-passenger-details"
                    >
                      <div className="itenary-container eticket-itenary-container booking-padding-top-40 eticket-booking-padding-top-40">
                        <div>{t("Passenger Details")}</div>
                      </div>

                      <div className="eticket-itenary-passenger-details">
                        <Table className="itenary-passenger-table">
                          <TableHead>
                            <TableRow className="eticket-passenger-table-header">
                              <TableCell
                                align="center"
                                className="itenary-passenger-table-header-pl-12 eticket-header-item eticket-header-item-font-weight s-no itenary-passenger-table-header-item-initial "
                              >
                                {t("Code")}
                              </TableCell>
                              <TableCell
                                align="center"
                                className="itenary-passenger-table-header-pl-12 eticket-header-item eticket-header-item-font-weight"
                              >
                                {t("Passenger(s)")}
                              </TableCell>
                              <TableCell
                                align="center"
                                className="itenary-passenger-table-header-pl-12 eticket-header-item eticket-header-item-font-weight"
                              >
                                {t("Ticket No")}
                              </TableCell>
                              <TableCell
                                align="center"
                                className="itenary-passenger-table-header-pl-12 eticket-header-item eticket-header-item-font-weight tenary-passenger-table-header-item-final"
                              >
                                {t("Paid Seat")}
                              </TableCell>
                              <TableCell
                                align="center"
                                className="itenary-passenger-table-header-pl-12 eticket-header-item eticket-header-item-font-weight tenary-passenger-table-header-item-final"
                              >
                                {t("Paid Baggage")}
                              </TableCell>
                              <TableCell
                                align="center"
                                className="itenary-passenger-table-header-pl-12 eticket-header-item eticket-header-item-font-weight"
                              >
                                {t("Paid Meal")}
                              </TableCell>

                              <TableCell
                                align="center"
                                className="itenary-passenger-table-header-pl-12 eticket-header-item eticket-header-item-font-weight itenary-passenger-table-header-item-final"
                              >
                                {t("Priority Check-IN / Sports Equipment")}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          {flight.passengers !== undefined &&
                            flight.passengers.length > 0 ? (
                            flight.passengers
                              .sort(function (a, b) {
                                if (a.paxType < b.paxType) {
                                  return -1;
                                }
                                if (a.paxType > b.paxType) {
                                  return 1;
                                }
                                return 0;
                              })
                              .map((passenger, index) => (
                                <TableBody key={index + passenger.ticketNumber}>
                                  <TableRow className="eticket-passenger-table-item">
                                    <TableCell
                                      align="left"
                                      className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12"
                                    >
                                      <TicketQRCode passenger={passenger}
                                        bookingDetails={flight} />
                                    </TableCell>

                                    <TableCell
                                      align="left"
                                      className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12"
                                    >
                                      {passenger.title} {passenger.firstName}{" "}
                                      {passenger.lastName}
                                    </TableCell>

                                    {passenger.ticketNumber ? (
                                      <TableCell
                                        align="left"
                                        className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12"
                                      >
                                        {passenger.ticketNumber}
                                      </TableCell>
                                    ) : (
                                      <TableCell
                                        align="center"
                                        className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12"
                                      >
                                        -
                                      </TableCell>
                                    )}

                                    {(!flight?.seats || flight?.seats.length === 0) ? (
                                      <TableCell
                                        align="center"
                                        className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12"
                                      >
                                        -
                                      </TableCell>
                                    ) : (
                                      <TableCell
                                        align="center"
                                        className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12"
                                      >
                                        {flight?.seats[index]?.seatNo || '-'}
                                      </TableCell>
                                    )}
                                    <TableCell
                                      align="left"
                                      className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12"
                                    >
                                      {getBaggageForPassenger(flight.baggages, passenger) &&
                                        getBaggageForPassenger(flight.baggages, passenger).length > 1 ? (
                                        <>
                                          {getBaggageForPassenger(flight.baggages, passenger)
                                            .map((bag, index) => (
                                              <>
                                                {bag.checkInBag}{" "}
                                                {flight.currency} {"  "}{" "}
                                                {bag.amount}
                                                {<br></br>}
                                                {getBaggageForPassenger(flight.baggages, passenger).length -
                                                  1 <
                                                  index
                                                  ? "\n"
                                                  : ""}{" "}
                                              </>
                                            ))}
                                        </>
                                      ) : (
                                        <>
                                          {getBaggageForPassenger(flight.baggages, passenger)
                                            .map(
                                              (bag) =>
                                                `${bag.checkInBag} +( ${flight.currency} ${bag.amount})`
                                            )}
                                        </>
                                      )}
                                    </TableCell>

                                    {filterMeals(flight, passenger).length > 0 ? (
                                      <TableCell align="center">
                                        {filterMeals(flight, passenger).length > 0 &&
                                          filterMeals(flight, passenger)
                                            .map((meal, index) => (
                                              <>
                                                {meal.description}{" "}
                                                {flight.currency} {"  "}{" "}
                                                {meal.amount}
                                                {<br></br>}
                                                {filterMeals(flight, passenger).length -
                                                  1 <
                                                  index
                                                  ? "\n"
                                                  : ""}{" "}
                                              </>
                                            ))}
                                      </TableCell>
                                    ) : (
                                      <TableCell align="center">
                                        -
                                      </TableCell>
                                    )}


                                    {passenger.spEQ ? (
                                      <TableCell
                                        align="left"
                                        className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12"
                                      >
                                        {passenger.spEQ}
                                      </TableCell>
                                    ) : (
                                      <TableCell
                                        align="center"
                                        className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12"
                                      >
                                        -
                                      </TableCell>
                                    )}
                                  </TableRow>
                                </TableBody>
                              ))
                          ) : (
                            <TableRow></TableRow>
                          )}
                        </Table>
                      </div>
                      {fareDecision === 'true' ? (
                        <>
                          <div className="itenary-container eticket-itenary-container booking-padding-top-40 eticket-booking-padding-top-40">
                            <div>{t("Fare Details")}</div>
                          </div>
                          <div className="eticket-itenary-passenger-details eticket-passenger-details">
                            <Table className="itenary-passenger-table">
                              <TableHead>
                                <TableRow className="eticket-passenger-table-header">
                                  <TableCell
                                    align="center"
                                    className="itenary-passenger-table-header-pl-12 eticket-header-item eticket-header-item-font-weight s-no itenary-passenger-table-header-item-initial "
                                  >
                                    {t("Pax")}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="itenary-passenger-table-header-pl-12 eticket-header-item eticket-header-item-font-weight"
                                  >
                                    {t("Base Fare")}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="itenary-passenger-table-header-pl-12 eticket-header-item eticket-header-item-font-weight"
                                  >
                                    {t("Tax")}
                                  </TableCell>

                                  {hideMarkup ? (
                                    ""
                                  ) : (
                                    <TableCell
                                      align="center"
                                      className="itenary-passenger-table-header-pl-12 eticket-header-item eticket-header-item-font-weight"
                                    >
                                      {t("Markup")}
                                    </TableCell>
                                  )}

                                  <TableCell
                                    align="center"
                                    className="itenary-passenger-table-header-pl-12 eticket-header-item eticket-header-item-font-weight itenary-passenger-table-header-item-final"
                                  >
                                    {t("Total")}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {flight.clientFare !== undefined &&
                                  flight.clientFare.length > 0 ? (
                                  sortFares(flight.clientFare)
                                    .map((fare, index) => (
                                      <TableRow key={`${index + 'fare'}`} className="eticket-passenger-table-item">
                                        <TableCell
                                          align="center"
                                          className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12"
                                        >
                                          {paxTypeLabels[fare.paxType]}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12"
                                        >
                                          {
                                            flight && Flight.supplierFare && flight.supplierFare.length > 0 && (flight?.supplierFare[0]?.fop === "PassThrough" || flight?.supplierFare[0]?.fop?.includes("PassThrough"))
                                              ? (
                                                <TableCell
                                                  align="center"
                                                  className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12"
                                                >
                                                  {getPassengerFare(fare, flight)}
                                                </TableCell>
                                              )
                                              : (
                                                <TableCell
                                                  align="center"
                                                  className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12"
                                                >
                                                  {getPassengerFare(fare, flight, false)}
                                                </TableCell>
                                              )
                                          }
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12"
                                        >
                                          {fare.tax}
                                        </TableCell>

                                        {hideMarkup ? (
                                          ""
                                        ) : (
                                          <TableCell
                                            align="center"
                                            className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12"
                                          >
                                            <ValidatorForm
                                              onError={(errors) =>
                                                console.log(errors)
                                              }
                                              onSubmit={() => {
                                                "";
                                              }}
                                            >
                                              <TextValidator
                                                variant="outlined"
                                                size="small"
                                                style={{ minWidth: " 90px" }}
                                                color="secondary"
                                                value={getMarkupValue(fare.paxType)}
                                                onChange={(event) => {
                                                  switch (fare.paxType) {
                                                    case "ADT":
                                                      setAdultValues(fare, flight.adultCount, event.target.value);
                                                      break;
                                                    case "CHD":
                                                      setChildValues(fare, flight.childCount, event.target.value);
                                                      break;
                                                    case "INF":
                                                      setINFvalues(fare, flight.infantCount, event.target.value);
                                                      break;
                                                    default:
                                                      setAdultValues(fare, flight.adultCount, event.target.value);
                                                  }
                                                }}
                                                validators={[
                                                  "required",
                                                  "matchRegexp:^[0-9\b]+$",
                                                ]}
                                                errorMessages={[
                                                  "required",
                                                  "Only number are allowed",
                                                ]}
                                              />
                                            </ValidatorForm>
                                          </TableCell>
                                        )}

                                        <TableCell
                                          align="center"
                                          className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12"
                                        >
                                          <b>
                                            {getMarkupTotalValue(fare, fare.paxType)}
                                          </b>

                                        </TableCell>
                                      </TableRow>
                                    ))
                                ) : (
                                  <TableRow></TableRow>
                                )}

                                {showRow && (
                                  <TableRow className="itenary-passenger-table-item">
                                    {!hideMarkup && (
                                      <>
                                        <TableCell className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12 eticket-baggage-price-txt"></TableCell>
                                        <TableCell className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12 eticket-baggage-price-txt"></TableCell>
                                        <TableCell className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12 eticket-baggage-price-txt"></TableCell>
                                        <TableCell
                                          className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12 eticket-baggage-price-label"
                                          align="center"
                                        >
                                          {t("Additional Service Charges")}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12 eticket-baggage-price-txt"
                                        >
                                          {flight.currency} {(additionalServiceTotalFare)}
                                        </TableCell>
                                      </>
                                    )}
                                  </TableRow>
                                )}
                                <TableRow className="itenary-passenger-table-item">
                                  {!hideMarkup && (
                                    <>
                                      <TableCell className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12 eticket-total-price-txt"></TableCell>
                                      <TableCell className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12 eticket-total-price-txt"></TableCell>
                                      <TableCell className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12 eticket-total-price-txt"></TableCell>
                                      <TableCell
                                        className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12 eticket-total-price-label"
                                        align="center"
                                      >
                                        <b>{t("Total")}</b>
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12 eticket-total-price-txt"
                                      >
                                        <b>
                                          {flight.currency} {clientTotalFare}
                                        </b>
                                      </TableCell>
                                    </>
                                  )}
                                </TableRow>
                              </TableBody>
                            </Table>
                          </div>
                        </>
                      ) : (
                        ""
                      )}

                      <div className="baggage-details">
                        <p id="importantNote" className="importantNote fs10">
                          <span>
                            <b>{t("Important Note")}: </b>{" "}
                            {"Refund/date change penalties upto 100% may apply.".toLowerCase()}
                          </span>
                          <br></br> <br></br>
                          <span>
                            <b>{t("Note")}: </b>
                            {"We have passed the Names to the Airline end as per the Airline Guidelines.".toLowerCase()}
                          </span>
                          <br></br> <br></br>
                          <span>
                            <b>{t("Notice")}: </b>
                            <br></br> <br></br>
                            <b>{t("DANGEROUS GOODS (HAZARDOUS MATERIALS)")}: </b>
                            {t("THE CARRIAGE OF CERTAIN HAZARDOUS MATERIALS, LIKE AEROSOLS, FIREWORKS, AND FLAMMABLE LIQUIDS, ABOARD THE AIRCRAFT IS FORBIDDEN.").toLowerCase()}
                            {t("IF YOU DO NOT UNDERSTAND THESE RESTRICTIONS, FURTHER INFORMATION MAY BE OBTAINED FROM YOUR AIRLINE.").toLowerCase()}
                            <br></br> <br></br>
                            <b>{t("DATA PROTECTION NOTICE:")} </b>
                            {t("YOUR PERSONAL DATA WILL BE PROCESSED IN ACCORDANCE WITH THE APPLICABLE CARRIER'S PRIVACY POLICY AND, IF YOUR BOOKING IS MADE VIA A RESERVATION SYSTEM PROVIDER (GDS), WITHITS PRIVACY POLICY.").toLowerCase()}
                            {t("THESE ARE AVAILABLE AT").toLowerCase()}
                            <Link href="HTTP://WWW.IATATRAVELCENTER.COM/PRIVACY">
                              (HTTP://WWW.IATATRAVELCENTER.COM/PRIVACY").toLowerCase()
                            </Link>
                            {t("OR FROM THE CARRIER OR GDS DIRECTLY.").toLowerCase()}
                            {t("YOU SHOULD READ THIS DOCUMENTATION, WHICH APPLIES TO YOUR BOOKING AND SPECIFIES, FOR EXAMPLE, HOW YOUR PERSONAL DATA IS COLLECTED, STORED, USED, DISCLOSED AND TRANSFERRED.").toLowerCase()}
                            <br></br> <br></br>
                            <b>
                              {t("ADVICE TO INTERNATIONAL PASSENGERS ON LIMITATIONS OF LIABILITY: ").toLowerCase()}
                            </b>
                            {t("PASSENGERS EMBARKING UPON A JOURNEY INVOLVING AN ULTIMATE DESTINATION OR A STOP IN A COUNTRY OTHER THAN THE COUNTRY OF DEPARTURE ARE ADVISED THAT THE PROVISIONS OF AN INTERNATIONAL TREATY (THE WARSAW CONVENTION, THE 1999 MONTREAL CONVENTION, OR OTHER  TREATY),").toLowerCase()}
                            {t("AS WELL AS A CARRIER'S OWN CONTRACT OF CARRIAGE OR TARIFF PROVISIONS, MAY BE APPLICABLE TO THEIR ENTIRE JOURNEY, INCLUDING ANY PORTION ENTIRELY WITHIN THE COUNTRIES OF DEPARTURE AND DESTINATION.").toLowerCase()}
                            {t("THE APPLICABLE TREATY GOVERNS AND MAY LIMIT THE LIABILITY OF CARRIERS TO PASSENGERS FOR DEATH OR PERSONAL INJURY, DESTRUCTION OR LOSS OF, OR DAMAGE TO, BAGGAGE, AND FOR DELAY OF PASSENGERS AND BAGGAGE.").toLowerCase()}
                            {t(" ADDITIONAL PROTECTION CAN USUALLY BE OBTAINED BY PURCHASING INSURANCE FROM A PRIVATE COMPANY.").toLowerCase()}
                            {t("SUCH INSURANCE IS NOT AFFECTED BY ANY LIMITATION OF THE CARRIER'S LIABILITY UNDER AN INTERNATIONAL TREATY.").toLowerCase()}
                            {t("FOR FURTHER INFORMATION PLEASE  CONSULT YOUR AIRLINE OR INSURANCE COMPANY  REPRESENTATIVE.").toLowerCase()}
                            <br></br> <br></br>
                            {t("CARRIER RESERVES THE RIGHT TO REFUSE CARRIAGE TO ANY PERSON WHO HAS ACQUIRED A TICKET IN VIOLATION OF APPLICABLE LAW OR CARRIER'S TARIFFS, RULES OR REGULATIONS SUBJECT TO TARIFF REGULATIONS.").toLowerCase()}
                          </span>
                        </p>
                      </div>
                    </div>
                  </>
                ))
              ) : (
                <div className="eticket-itenary-passenger-details"></div>
              )}
            </div>
          </div>
        </div>{" "}
      </div>
      <div
        tabIndex="0"
        onMouseEnter={onHover}
        onMouseLeave={onLeave}
        className="pdficonContainer"
        onClick={downloadTicket}
      >
        <PictureAsPdfIcon className=" pdficon" />
      </div>
      {hover ? (
        <div className="hoverTextContainer">
          <p className="hoverText">{t("click to Download")}</p>
        </div>
      ) : (
        ""
      )}
    </>
  );
  function fixCapitalsText(text) {
    let result = "";

    let sentenceStart = true;
    for (let i = 0; i < text.length; i++) {
      let ch = text.charAt(i);

      if (sentenceStart && ch.match(/^\S$/)) {
        ch = ch.toUpperCase();
        sentenceStart = false;
      } else {
        ch = ch.toLowerCase();
      }

      if (ch.match(/^[.!?]$/)) {
        sentenceStart = true;
      }

      result += ch;
    }

    return result;
  }

  function fixCapitalsNode(node) {
    if (node) {
      if (node.nodeType === 3 || node.nodeType === 4) {
        node.textContent = fixCapitalsText(node.textContent);
      }
      if (node.nodeType === 1) {
        for (var i = 0; i < node.childNodes.length; i++)
          fixCapitalsNode(node.childNodes.item(i));
      }
    }
  }
}
export default Eticket;
