import React from "react";
import "./Results.css";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import useConfigurationTableRow from "../../../hooks/useConfigurationTableRow";
import useConfigurationTableCell from "../../../hooks/useConfigurationTableCell";
import moment from "moment";
import { checkIfUserStaff } from "../../../services/AuthService";
import { encryptData } from "../../../providers/dataEncryptDecryptor";
import { useTranslation } from "react-i18next";

export default function Results(props) {
	const { t } = useTranslation();
	const StyledTableRow = useConfigurationTableRow;
	const StyledTableCell = useConfigurationTableCell;
	function openNewTab(result) {
		sessionStorage.setItem("orderId", encryptData(result));

		let link = "";
		if (props.serviceType === 'Flight') {
			link = "/flight/view-booked-tickets";
		} else if (props.serviceType === 'Rail') {
			link = "/rail/ViewBookedTickets";
		}

		window.open(link, "_blank");
	}
	let results = props.documents.map((result, index) => {
		return (
			<StyledTableRow
				key={index}
				onClick={() => openNewTab(result?.document?.OrderRefNumber)}
				className='pointer'>
				<StyledTableCell component='th' scope='row'>
					<div className='table-cell-title'>
						{result?.document?.OrderRefNumber}
					</div>
				</StyledTableCell>
				<StyledTableCell component='th' scope='row'>
					<div className='table-cell-title'>
						{result?.document?.OrderRefNumber?.startsWith('GFS') ? 'Flight' : result?.document?.OrderRefNumber?.startsWith('GRS') ? 'Rail' : ''}
					</div>
				</StyledTableCell>
				<StyledTableCell component='th' scope='row'>
					<div className='table-cell-title'>{
						(!checkIfUserStaff() && result?.document?.BookingStatus === "Refund_Initiated") ? 'Cancelled' : (!checkIfUserStaff() && result?.document?.BookingStatus === "Void_Refunded") ? 'Refunded' : (!checkIfUserStaff() && result?.document?.BookingStatus === "Voided") ? 'Cancel_Requested' : result?.document?.BookingStatus
					}</div>
				</StyledTableCell>
				<StyledTableCell component='th' scope='row'>
					<div className='table-cell-title'>{
						// result.document.Status
						(!checkIfUserStaff() && result?.document?.Status === "Refund_Initiated") ? 'Cancelled' : (!checkIfUserStaff() && result?.document?.Status === "Void_Refunded") ? 'Refunded' : (!checkIfUserStaff() && result?.document?.Status === "Voided") ? 'Cancel_Requested' : result?.document?.Status
					}</div>
				</StyledTableCell>

				<StyledTableCell>
					<div className='table-cell-description'>{result?.document?.Origin}</div>
					<div className='table-cell-description'>
						{result?.document?.Destination}
					</div>
				</StyledTableCell>
				<StyledTableCell>
					<div className='table-cell-title'>{result?.document?.ClientName}</div>
				</StyledTableCell>
				<StyledTableCell>
					{
						props?.serviceType === 'Flight' && result?.document?.OrderSegment?.length > 0 ?
							<div className='table-cell-title'>
								{
									result?.document?.OrderSegment[0]?.AirlinePNR ? result?.document?.OrderSegment[0]?.AirlinePNR : " - "
								}
							</div>
							: ''
					}
					{
						props.serviceType === 'Rail' && result?.document?.RailOrderSegment?.length > 0 ?
							<div className='table-cell-title'>
								{
									result?.document?.RailOrderSegment[0]?.PNR ? result?.document?.RailOrderSegment[0]?.PNR : " - "
								}
							</div>
							: ''
					}
				</StyledTableCell>
				{
					props.serviceType === 'Flight' ?

						<StyledTableCell>
							<div className='table-cell-title'>
								{result?.document?.ValidatingAirlineName}
							</div>
						</StyledTableCell>
						: ''}
				{checkIfUserStaff() && (
					<StyledTableCell>
						<div className='table-cell-title'>{result?.document?.PCC}</div>
						<div className='table-cell-description'>
							{result?.document?.Supplier}
						</div>
					</StyledTableCell>
				)}
				<StyledTableCell align="right">
					{result?.document?.BookingSource}
				</StyledTableCell>
				<StyledTableCell align='right'>
					<div className='table-cell-description'>
						Created by:{" "}
						<span style={{ color: "black" }}>
							{result?.document?.CreatedByName}
						</span>
					</div>
					<div className='table-cell-description'>
						Created on:
						{moment(result?.document?.CreatedOnDate)
							.utc()
							.format("DD/MM/YYYY")}
						{" , "}
						{(result?.document?.CreatedOnDate).split("T")[1].split(".")[0]}
						{/* {moment(result?.document?.CreatedOnDate)
							.utc()
							.format("HH:MM:SS")} */}
					</div>
					{result?.document?.ActionedOnDate && <div className='table-cell-description'>
						Modified on:
						{moment(result?.document?.ActionedOnDate)
							.utc()
							.format("DD/MM/YYYY")}
						{" , "}
						{(result?.document?.ActionedOnDate).split("T")[1].split(".")[0]}
						{/* {moment(result?.document?.ActionedOnDate)
							.utc()
							.format("HH:MM:SS")} */}
					</div>}
				</StyledTableCell>
			</StyledTableRow>
		);
	});

	return (
		<div>
			<TableContainer component={Paper} className='table-paper-styles'>
				<Table className='table-background'>
					<TableHead>
						<TableRow>
							<TableCell>{t("GFS ID")}</TableCell>
							<TableCell>{t("Service Type")}</TableCell>
							<TableCell>{t("Booking Status")}</TableCell>
							<TableCell>{t("Current Status")}</TableCell>
							<TableCell>{t("Origin-Destination")}</TableCell>
							<TableCell>{t("Client Name")}</TableCell>
							<TableCell>{t("PNR")}</TableCell>
							{props.serviceType === 'Flight' ? <TableCell>{t("Airline")}</TableCell> : ''}
							{checkIfUserStaff() && <TableCell>{t("Supplier")}</TableCell>}
							<TableCell>{t("Booking Source")}</TableCell>
							<TableCell align='right' width={220}>
								{t("Created By")}
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>{results}</TableBody>
				</Table>
			</TableContainer>
		</div>
	);
}
