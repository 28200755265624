import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const PageTracker = () => {
    const location = useLocation();

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("user"));
        const trackPageView = (path) => {
            if (!user) return;
            window.dataLayer.push({
                event: 'page_view',
                page_path: path,
                user_id: user.user_id,
                user_email: user.email_id,
            });
        };

        trackPageView(location.pathname); // Track page load

        return () => {
            trackPageView(location.pathname); // Track navigation change
        };
    }, [location]);

    return null;
};

export default PageTracker;
