import React, { useEffect, useState } from "react";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Container } from "@material-ui/core";
import logo from "../../assets/gf.png";
import Popover from "@material-ui/core/Popover";
import { Paper } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";
import { login } from "../../Redux/AuthSlice";
import { setAuthHeader } from "../../utils/axiosHeaders";
import { checkIfUserStaff, isClientUserHasPermission } from "../../services/AuthService";
import Dialog from "@mui/material/Dialog";
import Otp from "./Otp/Otp";
// import TagManager from "react-gtm-module";
import browserSignature from 'browser-signature';
import RiseLoading from "../Loader/RiseLoader";
import { Input } from "@mui/joy";
import getIP from "../../providers/getIP";
import { useTranslation } from "react-i18next";
import "./SignInHead.css";
function ElevationScroll(props) {
  const { children } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}
const useStyles = makeStyles((theme) => ({
  toolbarMargin: {
    ...theme.mixins.toolbar,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  logo: {
    height: "4em",
    [theme.breakpoints.down("md")]: {
      height: "4em",
    },
    [theme.breakpoints.down("xs")]: {
      height: "4em",
    },
  },
  appbar: {
    zIndex: theme.zIndex.modal + 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  loginButton: {
    marginRight: "10px",
  },

  tab: {
    ...theme.typography.tab,
    minWidth: 10,
    textTransform: "capitalize",
    fontWeight: "bold",
    fontFamily: "Rubik,sans-serif",
  },
  scaddDrawer: {
    width: 350,
    flexShrink: 0,
    zIndex: theme.zIndex.drawer + 1,
  },
  scaddDrawerPaper: {
    width: 350,
    paddingTop: 64,
    paddingLeft: "20px",
    paddingRight: "20px",
    backgroundColor: "#e4e4e4",
    boxShadow:
      "0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
  },
  textField: {
    borderBottom: "1px solid #d3d3d3",
  },
}));

function SignInHead({ setUser, setAlert, setDisplayHeader }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(false);
  const url = getCurrentURL();
  const { t } = useTranslation();

  const handleClick = (event) => { };

  const handleClose = () => {
    setAnchorEl(false);
  };

  const history = useHistory();
  const dispatch = useDispatch();

  const [userName, setuserName] = useState("");
  const [userNameHelper, setuserNameHelper] = useState("");

  const [password, setpassword] = useState("");
  const [passwordHelper, setpasswordHelper] = useState("");

  const [forgotUserName, setforgotUserName] = useState("");
  const [forgotUserNameHelper, setforgotUserNameHelper] = useState("");

  const [signInForgotPassPanelSH, setSignInForgotPassPanelSH] = useState(false);
  const [otpPopUp, setOtpPopUp] = useState(false);
  const [otp, setOtp] = useState(new Array(4).fill(''));
  const [expired, setExpired] = useState(0);
  const [resOtpTime, setResOtpTime] = useState("");
  const signature = browserSignature();
  const [newBrowser, setNewBrowser] = useState(false);
  const [disableOtpInput, setDisableOtpInput] = useState(false);
  const [resMsg, setResMsg] = useState("");
  const [notValid, setNotValid] = useState(false);
  const [retryCount, setRetryCount] = useState(false);
  const [resUserData, setResUserData] = useState();
  const [resToken, setResToken] = useState();
  const [resRefreshToken, setResRefreshToken] = useState();
  const [showLoader, setShowLoader] = useState();

  const onForgotChangeSCForm = (event) => {
    let valid;
    switch (event.target.id) {
      case "forgetEmail":
        setforgotUserName(event.target.value);
        valid = event.target.value.length !== 0;
        if (!valid) {
          setforgotUserNameHelper(t("Please enter user name"));
        } else {
          setforgotUserNameHelper("");
        }
        break;
      default:
        break;
    }
  };

  const onChangeSCForm = (event) => {
    let valid;
    debugger;
    switch (event.target.id) {
      case "email":
        setuserName(event.target.value);
        valid = event.target.value.length !== 0;
        if (!valid) {
          setuserNameHelper(t("Please enter user name"));
        } else {
          setuserNameHelper("");
        }
        break;
      case "password":
        setpassword(event.target.value);
        valid = event.target.value.length !== 0;
        if (!valid) {
          setpasswordHelper(t("Please enter password"));
        } else {
          setpasswordHelper("");
        }
        break;
      default:
        break;
    }
  };

  async function resetPasswordAPI(username) {
    const headers = {
      "Content-Type": "application/json",
    };
    await axios
      .post(
        process.env.REACT_APP_CORE_URL + "ids/User/ResetPassword",
        JSON.stringify({
          email: username,
        }),
        {
          headers,
        }
      )
      .then((response) => {
        // console.log(response);
        setAlert({
          open: true,
          message:
            t("Password reset request was sent successfully. Please check your email to reset your password."),
          severity: "success",
        });
        setforgotUserName("");
        setforgotUserNameHelper("");
        setSignInForgotPassPanelSH(false);
      })
      .catch((err) => {
        // console.log(err.response.data.detail);
        setAlert({
          open: true,
          message: err.response.data.detail,
          severity: "error",
        });
        setforgotUserName("");
        setforgotUserNameHelper("");
      });
  }
  function getCurrentURL() {
    return window.location.href;
  }
  async function getToken(username, password) {
    setShowLoader(true);
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    const data =
      "grant_type=password&client_id=" +
      process.env.REACT_APP_CLIENTID +
      "&username=" +
      username +
      "&password=" +
      encodeURIComponent(password) +
      "&scope=" +
      process.env.REACT_APP_SCOPE;
    await axios
      .post(process.env.REACT_APP_IDENTITY_SERVER + "connect/token", data, {
        headers,
      })
      .then((response) => {
        dispatch(login());
        setResToken(response.data.access_token);
        setResRefreshToken(response.data.refresh_token);
        localStorage.setItem("token", response.data.access_token);
        localStorage.setItem("refreshtoken", response.data.refresh_token);
        setAuthHeader(response.data.access_token);
        // setUser(response.data.access_token);
        getUser();
      })
      .catch((error) => {
        setAlert({
          open: true,
          message: error.response.data.error_description ? error.response.data.error_description : t("Oops!! Invalid Username or Password"),
          severity: "error",
        });
        setShowLoader(false);
      });
  }


  const checkClientIsActive = async (userData, refId) => {
    await axios
      .get(
        process.env.REACT_APP_CORE_URL +
        "api/Configuration/ClientProfile/" +
        userData.clientref_id
      )
      .then((response) => {
        if (userData.isEnabled === "False") {
          setShowLoader(false);
          localStorage.removeItem("token");
          localStorage.removeItem("refreshtoken");

          setAlert({
            open: true,
            message:
              t("User is In-Active.Please Contact Your Client Administrator"),
            severity: "error",
          });
        } else {
          checkUserAuthorization(userData, refId);

          // localStorage.setItem("user", JSON.stringify(userData));
          // const userRole = getCurrentRole();

          // if ((isClientUserHasPermission("IsSearch", "FlightbookDetail") && !checkIfUserStaff()) || userRole === "ClientSuperAdmin") {
          //   history.push("/flight");
          // }
          // else {
          //   history.push("/Unauthorized");
          // }
        }
      })
      .catch((err) => {
        console.log(err)
        localStorage.removeItem("token");
        localStorage.removeItem("refreshtoken");

        setAlert({
          open: true,
          message:
            t("Dear Trade Partner : Your ID has been blocked , Please contact Email : customersuccess@getfares.com"),
          severity: "error",
        });
        setShowLoader(false);
      });

  };

  async function getUser() {
    await axios
      .get(process.env.REACT_APP_IDENTITY_SERVER + "connect/userinfo")
      .then((response) => {
        setResUserData(response.data);
        if (response.data.clientref_id !== "0" && window.location.origin === 'https://uat.getfares.com') {
          checkClientIsActive(response.data);
        } else if (window.location.origin === 'https://adminuat.getfares.com' || window.location.origin.includes('localhost')) {
          if (response.data.isEnabled === "True" && response.data.clientref_id === "0") {
            checkUserAuthorization(response.data, response.data.clientref_id);
          }
          else if (response.data.clientref_id !== "0") {
            checkClientIsActive(response.data, response.data.clientref_id);
          }
          else {
            localStorage.removeItem("token");
            localStorage.removeItem("refreshtoken");
            setAlert({
              open: true,
              message:
                t("User is In-Active. Please Contact customersuccess@getfares.com"),
              severity: "error",
            });
            setShowLoader(false);
          }
        }
        else {
          history.push("/Unauthorized");
          setShowLoader(false);
        }
      });
  }

  const onForgotForm = () => {
    var formValid = forgotUserName.length !== 0;
    if (forgotUserName.length === 0) {
      setforgotUserNameHelper(t("please enter user name"));
    } else {
      setforgotUserNameHelper("");
    }

    if (formValid) {
      //write login logic
      resetPasswordAPI(forgotUserName);
    } else {
      setAlert({
        open: true,
        message: t("oops!! Validation Error"),
        severity: "error",
      });
    }
  };

  const onScComform = () => {
    const otpInvalid = localStorage.getItem("otpFail");
    if (otpInvalid) {
      setDisableOtpInput(true);
      setNotValid(true);
      setResMsg("too_many_attempts");
    }
    var formValid = userName.length !== 0 && password.length !== 0;
    if (userName.length === 0) {
      setuserNameHelper(t("please enter user name"));
    } else {
      // setuserNameHelper("");
      // hidden to handle - Delay of clearing fields when user clicks in sign in button after entering value into these fields.
    }
    if (password.length === 0) {
      setpasswordHelper(t("please enter password"));
    } else {
      // setpasswordHelper("");
    }
    if (formValid) {
      //write login logic
      getToken(userName, password);
    } else {
      setAlert({
        open: true,
        message: t("oops!! Validation Error"),
        severity: "error",
      });
    }

    // setOtpPopUp(true);


  };
  const [value, setValue] = React.useState(1);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleOtpPopClose = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    setOtpPopUp(false);
  }

  useEffect(() => {
    const otpInvalid = localStorage.getItem("otpFail");
    if (otpInvalid) {
      setDisableOtpInput(true);
      setNotValid(true);
      setResMsg(t("too_many_attempts"));
    }
  }, [])


  useEffect(() => {
    setOtp(new Array(4).fill(''));
    if (!disableOtpInput) {
      setResMsg("");
    }
  }, [otpPopUp])

  useEffect(() => {
    if (expired === true) {
      const timer = setTimeout(() => {
        setOtpPopUp(false);
        setDisableOtpInput(false);
        localStorage.removeItem("otpFail");
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [expired]);


  const checkUserAuthorization = async (userData, refId) => {

    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    const ip = await getIP();

    const data = "BrowserSignature=" + signature + "&UserEmail=" + userName + `&OTP=0&IPAddress=` + ip;
    // const data = "BrowserSignature=" + signature + "&UserEmail=" + userName + `&OTP=0`;


    await axios
      .post(process.env.REACT_APP_CORE_URL + "api/Configuration/EmailVerification/OTPAuthorization", data, {
        headers,
      })
      .then((response) => {
        if (response.data.includes("UnAuthorized User")) {
          const dateTimeString = response.data.match(/UnAuthorized User - (\d{2}\/\d{2}\/\d{4} \d{2}:\d{2}:\d{2})/)[1];
          const expiryTime = new Date(dateTimeString);
          const localExpiryTime = new Date(expiryTime.getTime() - expiryTime.getTimezoneOffset() * 60000);
          const expiryTimeUTC = new Date(localExpiryTime.toISOString());

          response.data.includes("New Browser") ? setNewBrowser(true) : setNewBrowser(false);
          setResOtpTime(expiryTimeUTC);
          setOtpPopUp(true);
          setShowLoader(false);
        } else if (response.data === "Authorized user") {
          setDisplayHeader(true);
          localStorage.setItem("user", JSON.stringify(userData));
          if ((isClientUserHasPermission("IsSearch", "FlightbookDetail") && !checkIfUserStaff()) || userData.role === "ClientSuperAdmin" || checkIfUserStaff()) {
            history.push("/flight");
          } else {
            if (refId === "0") {
              window.location.replace(`${window.location.origin}/SignIn`, "_parent");
            } else {
              history.push("/Unauthorized");
            }
          }
          setOtpPopUp(false);
          setShowLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setShowLoader(false);
      });
  };



  return (
    <React.Fragment>
      <ElevationScroll>
        <Container
          fixed
          className="headerContainer"
          style={{
            maxWidth: "100%",
            padding: "0px",
          }}
        >
          <div className="TopText" style={{ fontFamily: 'Montserrat' }}>{t('top_text')}</div>
          <div className="loginContainer">
            <img
              alt="company logo"
              className="companyLogo"
              src={logo}
            />

            <div className="sign-in-section">
              <input type="hidden" id="email"></input>
              <input type="hidden" id="password"></input>
              <div style={{ position: "relative" }}>
                <Input size="lg" className="signInInput fs14" id="email" placeholder={t('username_placeholder')} type="email" value={userName}
                  onChange={onChangeSCForm} /></div>
              <div style={{ position: "relative" }}>
                <Input size="lg" className="signInInput fs14" id="password" placeholder={t('password_placeholder')} type="password" value={password}
                  onChange={onChangeSCForm} />
                <span
                  className="login-hyperlink fs14 fw300"
                  style={{ fontFamily: 'Kanit', }}
                  onClick={() => {
                    setSignInForgotPassPanelSH(!signInForgotPassPanelSH);
                    setAnchorEl(true);
                  }}
                >
                  {t('forgot_password')}
                </span>
              </div>
              <div className="signinButtonContainer">
                <Button
                  disabled={otpPopUp || showLoader}
                  variant="contained"
                  // color="primary"
                  className={`${classes.submit} fs16 fw500`}
                  onClick={onScComform}
                  disableElevation
                  size="large"
                  style={{
                    width: "150px",
                    padding: "10px",
                    textTransform: "capitalize",
                    margin: "10px",
                    marginRight: '0',
                    backgroundColor: "#D82A61",
                    color: "#fff",
                    borderRadius: "50px",
                  }}
                >
                  {t('login_button')}
                </Button>

              </div>
            </div>
          </div>
          {signInForgotPassPanelSH === true && (
            <Popover
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              className="forgotPassword"
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <Paper
                elevation={0}
                style={{
                  width: "400px",
                  padding: "20px",
                  backgroundColor: "#f8fafe",
                }}
              >
                <>
                  <div className="forgot-password-section mb-3">
                    <div className="forgot-main-heading">
                      {t('reset_password')}
                    </div>
                    <div className="forgot-sub-heading">
                      {t("reset_message")}
                    </div>
                    <div className="mb-2">
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="forgetEmail"
                        label="Email Address"
                        name="forgetEmail"
                        error={forgotUserNameHelper.length !== 0}
                        helperText={forgotUserNameHelper}
                        value={forgotUserName}
                        onChange={onForgotChangeSCForm}
                        InputProps={{
                          className: classes.textField,
                        }}
                      />
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={onForgotForm}
                        disableElevation
                        size="large"
                        style={{ width: "200px" }}
                      >
                        Send Reset Link
                      </Button>
                    </div>


                  </div>
                </>{" "}
              </Paper>{" "}
            </Popover>
          )}
        </Container>
      </ElevationScroll>
      {/* <div className={classes.toolbarMargin} /> */}
      <Dialog
        open={otpPopUp}
        // onClose={() => setOtpPopUp(false)}
        onClose={handleOtpPopClose}
      >
        <Otp
          setOtpPopUp={setOtpPopUp}
          userName={userName}
          otp={otp}
          setOtp={setOtp}
          signature={signature}
          resOtpTime={resOtpTime}
          setResOtpTime={setResOtpTime}
          getUser={getUser}
          setNewBrowser={setNewBrowser}
          newBrowser={newBrowser}
          expired={expired}
          setExpired={setExpired}
          disableOtpInput={disableOtpInput}
          setDisableOtpInput={setDisableOtpInput}
          resMsg={resMsg}
          setResMsg={setResMsg}
          notValid={notValid}
          setNotValid={setNotValid}
          retryCount={retryCount}
          setRetryCount={setRetryCount}
          setDisplayHeader={setDisplayHeader}
          resUserData={resUserData}
          resToken={resToken}
          resRefreshToken={resRefreshToken}
        />
      </Dialog>
      {showLoader && <RiseLoading isSignIn={showLoader} />}
    </React.Fragment>
  );
}

export default SignInHead;
