export default function checkStopsFilter(item, search) {
  let stops = search.filters.stops;
  let checkedStops = stops.filter((stop) => stop.checked === true);
  if (checkedStops.length === 0) {
    return true;
  } else {
    let isAvailable = true;

    item.segGroups.forEach((segGroup) => {
      let isAnyOneStopAvail = false;
      if (segGroup.segs.length === 1) {
        checkedStops.forEach((checkedStop) => {
          if (checkedStop.title === "Non Stop") {
            isAnyOneStopAvail = true;
          }
        });
      }
      else if (segGroup.segs.length === 2) {
        checkedStops.forEach((checkedStop) => {
          if (checkedStop.title === "1 Stop") {
            isAnyOneStopAvail = true;
          }
        });
      }
      else if (segGroup.segs.length > 2) {
        checkedStops.forEach((checkedStop) => {
          if (checkedStop.title === "2+ Stop") {
            isAnyOneStopAvail = true;
          }
        });
      }
      if (isAnyOneStopAvail === false) {
        isAvailable = false;
      }
    });

    // checkedStops.forEach((checkedStop) => {
    //   let isSegmentAvail = true;
    //   if (checkedStop.title === "Non Stop") {
    //     item.segGroups.forEach((segGroup) => {
    //       if (segGroup.segs.length > 1) {
    //         isSegmentAvail = false;
    //       }
    //       else if (segGroup.segs.length === 1) {
    //         isSegmentAvail = false;
    //       }
    //     });
    //   } else if (checkedStop.title === "1 Stop") {
    //     item.segGroups.forEach((segGroup) => {
    //       if (segGroup.segs.length < 2 || segGroup.segs.length > 2) {
    //         isSegmentAvail = false;
    //       }
    //     });
    //   } else if (checkedStop.title === "2+ Stop") {
    //     item.segGroups.forEach((segGroup) => {
    //       if (segGroup.segs.length < 2) {
    //         isSegmentAvail = false;
    //       }
    //     });
    //   }
    //   if (isSegmentAvail === true) {
    //     isAvailable = true;
    //   }
    // });
    console.log("checkStopsFilter: " + isAvailable);
    return isAvailable;
  }
}
