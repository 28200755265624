import React, { Fragment, Suspense, useEffect, useState } from "react";
import "./App.css";
import { Route, BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import LandingPage from "./components/LandingPage/LandingPage";
import Search from "./providers/Searchbar/Search";
import Booking from "./providers/Booking/Booking";
import SearchResultsPage from "./components/SearchResultsPage/SearchResultsPage";
import NewSearchResultsPage from "./components/SearchResultsPageNew/index";
import ReviewBooking from "./components/ReviewBooking/ReviewBooking";
import Configuration from "./components/Configuration/Configuration";
import Header from "./components/Header/Header";
import ViewBookedTickets from "./components/ViewBooking/ViewBooking";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./Theme";
//sign in dependencies
import PrivateRoute from "./utils/protectedRoute";
import SignIn from "./components/Login/SignIn";
import ManageProfile from "./components/ManageProfile/ManageProfile";
import Administration from "./components/Administration/Administration";
import ManageClients from "./components/ManageClinets/ManageClients";
import Vendor from "./components/Configuration/Vendor/Vendor";
import Supplier from "./components/Configuration/Supplier/Supplier";
import ManageUser from "./components/ManageUsers/ManageUser";
import RegisterUser from "./components/ManageUsers/RegisterUser/RegisterUser";
import RegisterClient from "./components/ManageClinets/RegisterClient/RegisterClient";
import ResetPassword from "./components/ManageResetPassword/ResetPassword";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import SupplierCommissionGroup from "./components/Configuration/SupplierCommissionGroup/SupplierCommissionGroup";
import SearchCategory from "./components/Configuration/SearchCategory/SearchCategory";
import axios from "axios";
import { setAuthHeader } from "../src/utils/axiosHeaders";
import SupplierCommissionBlock from "./components/Configuration/SupplierCommissionBlock/SupplierCommissionBlock";
import RoleRights from "./components/RoleRights/RoleRights";
import SearchCategoryConfiguration from "./components/Configuration/SearchCategoryConfiguration/SearchCategoryConfiguration";
import BookingSwitches from "./components/Configuration/BookingSwitches/BookingSwitches";
import PendingApprovalQueue from "./components/PendingApprovalQueue/PendingApprovalQueue";
import ViewVendor from "./components/Configuration/Vendor/ViewVendor/ViewVendor";
import ViewClient from "./components/ManageClinets/ViewClient/ViewClient";
import TicketingConfiguration from "./components/Configuration/TicketingConfiguration/TicketingConfiguration";
import QueueTable from "./components/MyQueue/QueueTable/QueueTable";
import MyQueue from "./components/MyQueue/MyQueue";
import Dashboard from "./components/Dashboard/Dashboard";

import MarkupGroup from "./components/Configuration/MarkupGroup/MarkupGroup";
import MarkupBlock from "./components/Configuration/MarkupBlock/MarkupBlock";
import FSH from "./components/Configuration/FlightSearchHistory/FSH";
import FSHDetails from "./components/Configuration/FlightSearchHistory/FSHDetails/FSHDetails";
import ImportBooking from "./components/ImportBooking/ImportBooking";
import OfflineBooking from "./components/OfflineBooking/OfflineBooking";
import DebitTransactions from "./components/Transactions/DebitTransactions";
import Invoice from "./components/Invoice/Invoice";
import Footer from "./components/Footer/Footer";
import ClientRoleRights from "./components/ClientRoleRights/ClientRoleRights";
import Unauthorizedtemplate from "./providers/UnauthorizedTemplate/Unauthorizedtemplate";
import ViewCancellationDetails from "./components/ViewCancellation/ViewCancellationDetails";
import Eticket from "./components/Eticket/Eticket";
import Reco from "./components/Configuration/Reconcilliation/Reco";
import Receipt from "./components/Receipt/Receipt";
import BankInfo from "./components/BankInfo/BankInfo";
import { CheckIsLoggedIn, checkIfUserStaff } from "../src/services/AuthService";
import SessionTimeout from "./services/SessionTimeout";
import { logout } from "./Redux/AuthSlice";
import { useDispatch } from "react-redux";
import ScrollToTopButton from "./providers/scrollToTop/ScrollToTop";
import AutoImps from "./components/Payment/autoImps";
import OperatingOND from "./components/Operating OND/OperatingOND";
import ViewCancellation from "./components/ViewCancellation/ViewCancellation";
import Terms from "./components/Footer/Terms";
import WalletTopUp from "./components/WalletTopUp/WalletTopUp";
import { FlightResponseProvider } from "./contexts/Searchbar/SearchResponseContext";
import AgentMarkup from "./components/Configuration/AgentMarkup/AgentMarkup";
import ClientBalanceReport from "./components/ClientBalanceReport/ClientBalanceReport";
import OfflineImportReport from "./components/OfflineImportReport/OfflineImportReport";
import TravelSchedule from "./components/Travel Schedule/TravelSchedule";
import PassengerProfile from "./components/PassengerProfile/PassengerProfile";
import QuoteCompletion from "./providers/QuoteCompletion";
import ImportPNR from "./components/ImportPNR/ImportPNR";
import OrderTraceReport from "./components/OrderTraceReport/OrderTraceReport"
import PassthroughConfiguration from "./components/Configuration/PassthroughConfiguration/PassthroughConfiguration";
import RechargeTransactions from "./components/WalletTopUp/RechargeTransactions";
import NotFound from "./components/NotFound/NotFound";
import L1CacheGroup from "./components/Configuration/L1CacheGroup/L1CacheGroup";
import DocumentUpload from "./components/ClientOnBoarding/DocumentUpload/DocumentUpload"
import ClientOnBoarding from "./components/ClientOnBoarding/ClientOnBoarding";
import getIP from "./providers/getIP";
import PaymentConfirmation from "./components/ReviewBooking/PaymentConfirmationPage";
import PageTracker from "./providers/PageTracker/PageTracker";
import { useTranslation } from 'react-i18next';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import LanguageSwitcher from "./providers/languageSwitcher";

function App() {
  const [user, setUser] = useState("");
  const [displayHeader, setDisplayHeader] = useState(true);
  const [displayFooter, setDisplayFooter] = useState(true);
  const { t, i18n } = useTranslation();

  // Get language from URL path (e.g., /ja, /it, etc.)
  // const { lang } = useParams();

  // useEffect(() => {
  //   if (lang && i18n.language !== lang) {
  //     i18n.changeLanguage(lang); // Switch language dynamically
  //   }
  // }, [lang, i18n]);


  const dispatch = useDispatch();



  async function getRefreshToken(refreshtoken) {
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    const data =
      "grant_type=refresh_token&client_id=" +
      process.env.REACT_APP_CLIENTID +
      "&refresh_token=" +
      refreshtoken;
    await axios
      .post(process.env.REACT_APP_IDENTITY_SERVER + "connect/token", data, {
        headers,
      })
      .then((response) => {
        localStorage.setItem("token", response.data.access_token);
        localStorage.setItem("refreshtoken", response.data.refresh_token);
        setAuthHeader(response.data.access_token);
        setUser(response.data.access_token);
      });
  }

  useEffect(() => {
    // TagManager.initialize({ gtmId: "GTM-PFX6CFH3" });
    const token = localStorage.getItem("token");
    setUser(token);
    const interval = setInterval(() => {
      if (user !== null) {
        const refreshtoken = localStorage.getItem("refreshtoken");
        console.log("calling refresh token every 30 min" + user);
        getRefreshToken(refreshtoken);
      }
    }, 1800000); //Old(540000 - 10 Sec) - Increase Refresh Token Time
    return () => clearInterval(interval);
  }, [user]);
  useEffect(() => {
    getIP();
    const interval = setInterval(() => {
      const tuser = localStorage.getItem("user");
      setUser(tuser);
      if (!tuser) {
        setUser("");
        logoutfn();
      }
    }, 300000);
    return () => clearInterval(interval);
  }, []);


  useEffect(() => {
    const token = localStorage.getItem("user");
    setUser(token);
    if (!token) {
      return (<Redirect to="/SignIn" />);
    }
  }, [user]);

  const logoutfn = async () => {
    setUser("");
    localStorage.clear();
    dispatch(logout());
    sessionStorage.clear();
    return (<Redirect to="/SignIn" />);
  };
  // const openRail = () => {
  //   window.open(`${window.location.origin}/rail`, '_parent')
  // }
  return (
    <div>
      <ScrollToTopButton />
      <LanguageSwitcher />
      <SessionTimeout logOut={logoutfn} />
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ThemeProvider theme={theme}>
          <Search>
            <Booking>
              <Suspense fallback={<div>Loading translations...</div>}>
                <Router>
                  <FlightResponseProvider>
                    <PageTracker />
                    {displayHeader && <Header user={user} setUser={setUser} />}
                    <div style={{ minHeight: "90vh", width: "100vw", backgroundColor: "#f4f4f4" }}>

                      <Switch>
                        <Route exact path="/SignIn">

                          <SignIn
                            setUser={setUser}
                            setDisplayHeader={setDisplayHeader}
                          />

                        </Route>

                        <PrivateRoute
                          exact
                          path="/Terms"
                          component={Terms}
                        />

                        <PrivateRoute
                          exact
                          path="/flight/Invoice"
                          component={Invoice}
                        />

                        <PrivateRoute
                          exact
                          path="/flight/Eticket"
                          component={Eticket}
                        />

                        <Route
                          exact
                          path="/RegisterUser"
                          component={RegisterUser}
                        >
                          <RegisterUser
                            setUser={setUser}
                            setDisplayHeader={setDisplayHeader}
                          />
                        </Route>

                        <Route
                          exact
                          path="/RegisterClient"
                          component={RegisterClient}
                        />

                        <Route
                          exact
                          path="/QuoteCompletion"
                          component={QuoteCompletion}
                        />

                        <Route
                          exact
                          path="/ResetPassword"
                          component={ResetPassword}
                        />

                        <Route
                          exact
                          path="/flight/view-booked-tickets"
                          component={ViewBookedTickets}
                        />

                        <Route
                          exact
                          path="/flight/ViewCancellation"
                          component={ViewCancellation}
                        />

                        <Route exact path="/">
                          {
                            CheckIsLoggedIn() ? (
                              <LandingPage />
                            ) : (
                              <Redirect to="/SignIn" />
                            )
                          }
                        </Route>

                        <Route exact path="/flight">
                          {
                            CheckIsLoggedIn() ? (
                              <LandingPage />
                            ) : (
                              <Redirect to="/SignIn" />
                            )
                          }
                        </Route>
                        <Route exact path="/rail">
                          {
                            CheckIsLoggedIn() ? (
                              <>
                                {/* {
                                openRail()
                              } */}
                              </>
                            ) : (
                              <Redirect to="/SignIn" />
                            )
                          }
                        </Route>
                        <Route exact path="/rail/">
                          {
                            CheckIsLoggedIn() ? (
                              <>
                                {/* {
                                openRail()
                              } */}
                              </>
                            ) : (
                              <Redirect to="/SignIn" />
                            )
                          }
                        </Route>

                        <PrivateRoute
                          exact
                          path="/flight/search-results"
                          component={SearchResultsPage}
                        />

                        <PrivateRoute
                          exact
                          path="/flight/search-results-new"
                          component={NewSearchResultsPage}
                        />

                        <PrivateRoute
                          exact
                          path="/flight/review-booking"
                          component={ReviewBooking}
                        />
                        <PrivateRoute
                          exact
                          path="/flight/PaymentConfirmation/:id"
                          component={PaymentConfirmation}
                        />
                        <PrivateRoute
                          exact
                          path="/ManageProfile"
                          component={ManageProfile}
                        />

                        <PrivateRoute
                          exact
                          path="/ManageUser/:id"
                          component={ManageUser}
                        />

                        <PrivateRoute
                          exact
                          path="/QueueTable"
                          component={QueueTable}
                        />

                        <PrivateRoute
                          exact
                          path="/DebitTransactions/:id"
                          component={DebitTransactions}
                        />

                        <PrivateRoute
                          exact
                          path="/ClientRoleRights"
                          component={ClientRoleRights}
                        />

                        <PrivateRoute
                          exact
                          path="/Unauthorized"
                          component={Unauthorizedtemplate}
                        />

                        <PrivateRoute
                          exact
                          path="/flight/ViewCancellationDetails"
                          component={ViewCancellationDetails}
                        />

                        <PrivateRoute
                          exact
                          path="/TravelSchedule"
                          component={TravelSchedule}
                        />

                        <PrivateRoute
                          exact
                          path="/AutoImps"
                          component={AutoImps}
                        />

                        <PrivateRoute
                          exact
                          path="/Recharge"
                          component={checkIfUserStaff() ? RechargeTransactions : WalletTopUp}
                        />

                        <PrivateRoute
                          exact
                          path="/Recharge/:id"
                          component={WalletTopUp}
                        />



                        <PrivateRoute
                          exact
                          path="/AgentMarkup"
                          component={AgentMarkup}
                        />

                        <PrivateRoute
                          exact
                          path="/PassengerProfile"
                          component={PassengerProfile}
                        />

                        <PrivateRoute
                          exact
                          path="/ImportPNR"
                          component={ImportPNR}
                        />
                        <Route path="/appdocs/:currency/:refno">
                          <DocumentUpload
                            setDisplayHeader={setDisplayHeader}
                            setDisplayFooter={setDisplayFooter}
                          />
                        </Route>

                        {
                          checkIfUserStaff() ? (

                            <Switch>


                              <PrivateRoute
                                exact
                                path="/configuration"
                                component={Configuration}
                              />

                              <PrivateRoute
                                exact
                                path="/Administration"
                                component={Administration}
                              />

                              <PrivateRoute
                                exact
                                path="/MyQueue"
                                component={MyQueue}
                              />

                              <PrivateRoute
                                exact
                                path="/BookingSwitches"
                                component={BookingSwitches}
                              />

                              <PrivateRoute
                                exact
                                path="/PendingApprovalQueue"
                                component={PendingApprovalQueue}
                              />

                              <PrivateRoute
                                exact
                                path="/ManageClients"
                                component={ManageClients}
                              />

                              <PrivateRoute
                                exact
                                path="/Supplier"
                                component={Supplier}
                              />

                              <PrivateRoute
                                exact
                                path="/Vendor"
                                component={Vendor}
                              />

                              <PrivateRoute
                                exact
                                path="/OrderTraceReport"
                                component={OrderTraceReport}
                              />

                              <PrivateRoute
                                exact
                                path="/SupplierCommissionGroup"
                                component={SupplierCommissionGroup}
                              />

                              <PrivateRoute
                                exact
                                path="/SupplierCommissionBlock/:id/:name"
                                component={SupplierCommissionBlock}
                              />

                              <PrivateRoute
                                exact
                                path="/RoleRights"
                                component={RoleRights}
                              />

                              <PrivateRoute
                                exact
                                path="/SearchCategory"
                                component={SearchCategory}
                              />

                              <PrivateRoute
                                exact
                                path="/SearchCategoryConfiguration/:id/:name"
                                component={SearchCategoryConfiguration}
                              />

                              <PrivateRoute
                                exact
                                path="/L1CacheGroup"
                                component={L1CacheGroup}
                              />

                              <PrivateRoute
                                exact
                                path="/ViewVendor"
                                component={ViewVendor}
                              />

                              <PrivateRoute
                                exact
                                path="/ViewClient"
                                component={ViewClient}
                              />

                              <PrivateRoute
                                exact
                                path="/TicketingConfiguration"
                                component={TicketingConfiguration}
                              />

                              <PrivateRoute
                                exact
                                path="/Dashboard"
                                component={Dashboard}
                              />

                              <PrivateRoute
                                exact
                                path="/MarkupGroup"
                                component={MarkupGroup}
                              />

                              <PrivateRoute
                                exact
                                path="/MarkupBlock/:id/:name"
                                component={MarkupBlock}
                              />

                              <PrivateRoute
                                exact
                                path="/FSH"
                                component={FSH}
                              />

                              <PrivateRoute
                                exact
                                path="/FSHDetails"
                                component={FSHDetails}
                              />

                              <PrivateRoute
                                exact
                                path="/Receipt"
                                component={Receipt}
                              />

                              <PrivateRoute
                                exact
                                path="/BankInfo"
                                component={BankInfo}
                              />

                              <PrivateRoute
                                exact
                                path="/ImportBooking"
                                component={ImportBooking}
                              />

                              <PrivateRoute
                                exact
                                path="/OfflineBooking"
                                component={OfflineBooking}
                              />

                              <PrivateRoute
                                exact
                                path="/Reco"
                                component={Reco}
                              />

                              <PrivateRoute
                                exact
                                path="/OperatingOND"
                                component={OperatingOND}
                              />

                              <PrivateRoute
                                exact
                                path="/ClientBalanceReport"
                                component={ClientBalanceReport}
                              />
                              <PrivateRoute
                                exact
                                path="/OfflineImportReport"
                                component={OfflineImportReport}
                              />

                              <PrivateRoute
                                exact
                                path="/PassthroughConfiguration"
                                component={PassthroughConfiguration}
                              />

                              <PrivateRoute
                                exact
                                path="/ClientOnBoardingQueue"
                                component={ClientOnBoarding}
                              />

                              <PrivateRoute path="*" component={NotFound} />
                            </Switch>

                          ) : (

                            <Switch>

                              <PrivateRoute exact path="/flight" component={LandingPage} />
                              <Route path="*" component={NotFound} />
                            </Switch>


                          )}



                      </Switch>

                    </div>
                    <Footer />
                  </FlightResponseProvider>
                </Router>
              </Suspense>
            </Booking>
          </Search>
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </div>
  );
}
export default App;
