import React, { useRef, useMemo, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import Button from 'react-bootstrap/Button'
import { IconButton, OutlinedInput, InputAdornment } from "@material-ui/core";
import "./TicketingConfigurationTable.css";
import { Chip } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { isUserHasPermission } from "../../../services/AuthService";
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import Icon from '@mui/material/Icon';

export default function TicketingConfigurationTable({ setModal, ticketingConfiguration }) {

    const columnDefs = [
        {
            headerName: "Pseudo Code",
            field: 'pcc',
            sortable: true,
            suppressMovable: true,
            // filter: 'agTextColumnFilter'
        }, {
            headerName: "Cabin Class",
            field: 'cabinClass',
            sortable: true,
            suppressMovable: true,
            // filter: true
        }, {
            headerName: "Ticketing Type",
            field: 'ticketingType',
            sortable: true,
            suppressMovable: true,
            // filter: true
        }, {
            headerName: "Queue Number",
            field: 'queueNumber',
            sortable: true,
            suppressMovable: true,
            valueGetter: function (params) {
                if (params.data.queueNumber.length > 0) {
                    return params.data.queueNumber;
                } else {
                    return "NA";
                }
            }
        }, {
            headerName: "Queue Format",
            field: 'queueFormat',
            sortable: true,
            suppressMovable: true,
            valueGetter: function (params) {
                if (params.data.queueFormat.length > 0) {
                    return params.data.queueFormat;
                } else {
                    return "NA";
                }
            }
        }, {
            headerName: "Void Number",
            field: 'voidNumber',
            sortable: true,
            suppressMovable: true,
            // valueGetter: function (params) {
            //     if (params.data.voidNumber.length > 0) {
            //         return params.data.voidNumber;
            //     } else {
            //         return "NA";
            //     }
            // }
        }, {
            headerName: "Void Queue Format",
            field: 'voidQueueFormat',
            sortable: true,
            suppressMovable: true,
            // valueGetter: function (params) {
            //     if (params.data.voidQueueFormat.length > 0) {
            //         return params.data.voidQueueFormat;
            //     } else {
            //         return "NA";
            //     }
            // }
        }, {
            headerName: "FOP",
            field: 'fop',
            sortable: true,
            suppressMovable: true,
        }, {
            headerName: "IsActive",
            cellRenderer: "isActiveCellRenderer",
            sortable: true,
            suppressMovable: true,
        }, {
            headerName: "Modified",
            sortable: true,
            suppressMovable: true,
            cellRenderer: "modifiedCellRenderer",
            wrapText: true,
            autoHeight: true,
            minWidth: 300,
        }, {
            headerName: "Actions",
            filter: false,
            suppressMovable: true,
            cellRenderer: "actionCellRenderer"
        }
    ]

    const gridRef = useRef();
    const rowHeight = 60;
    const RowsPerPage = 10;

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            // minWidth: 100,
            headerClass: "ticket-config-table-header",
            resizable: true,
        };
    }, []);

    const onGridReady = agGridRef => gridRef.current = agGridRef;

    const ActionCellRenderer = params => {
        return <>
            <Button
                className='ticket-config-action-btn'
                variant="outline-*"
                onClick={() => setModal({ status: true, action: "view", data: params.data.action.ticketingConfigurations })}
            >
                <VisibilityIcon />
            </Button>

            {isUserHasPermission('TicketingConfigurationWrite') ? <Button
                className='ticket-config-action-btn'
                variant="outline-*"
                onClick={() => setModal({ status: true, action: "edit", data: params.data.action.ticketingConfigurations })}
            >
                <EditIcon />
            </Button> : ""}
        </>
    }

    const ModifiedCellRenderer = params => {
        return <>
            <div className='ticket-config-cell-renderer'>
                <div className="modified-description">Created by: <span style={{ color: "black" }}>{params.data.action.createdBy}</span></div>
                <div className="modified-description">
                    Created on: {params.data.action.ticketingConfigurations.created.replace('T', " , ")}
                </div>
                <div className="modified-description">Last Modified by: <span style={{ color: "black" }}>{params.data.action.modefiedBy}</span></div>
                <div className="modified-description">
                    Last Modified on : {params.data.action.ticketingConfigurations.modified.replace('T', " , ")}
                </div>
            </div>
        </>
    }
    const isActiveCellRenderer = params => {
        return <>
            {params.data.action.ticketingConfigurations.isActive ? (
                <div className='ticket-config-cell-renderer'>
                    <Chip
                        label="Active"
                        color="primary"
                        size="small"
                        style={{ backgroundColor: "green" }}
                    />
                </div>
            ) : (
                <div className='ticket-config-cell-renderer'>
                    <Chip
                        label="In Active"
                        color="primary"
                        style={{ backgroundColor: "red" }}
                    />
                </div>
            )}
        </>
    }

    // constructing rows based on data
    const constructedRowData = dataArr => {
        return (dataArr || []).map(details => ({
            pcc: details.ticketingConfigurations.pcc,
            cabinClass: details.ticketingConfigurations.cabinClass,
            ticketingType: details.ticketingConfigurations.ticketingType,
            queueNumber: details.ticketingConfigurations.queueNumber,
            queueFormat: details.ticketingConfigurations.queueFormat,
            // voidNumber: details.ticketingConfigurations.voidNumber,
            // voidQueueFormat: details.ticketingConfigurations.voidQueueFormat,
            fop: details.ticketingConfigurations.fop,
            // isActive: details.ticketingConfigurations.isActive,
            action: details
        }));
    }

    // options to control grid
    const gridOptions = {
        domLayout: 'autoHeight',
        suppressCellSelection: true,
        frameworkComponents: {
            isActiveCellRenderer: isActiveCellRenderer,
            modifiedCellRenderer: ModifiedCellRenderer,
            actionCellRenderer: ActionCellRenderer
        },
        icons: {
            next: '<i class="material-icons"> > </i>',
            previous: '<i class="material-icons"> < </i>',
            first: '<i class="material-icons"> << </i>',
            last: '<i class="material-icons"> >> </i>',
            sortAscending: '<span class="material-symbols-outlined"><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#ffffff"><path d="M440-800v487L216-537l-56 57 320 320 320-320-56-57-224 224v-487h-80Z"/></svg></span>',
            sortDescending: '<span class="material-symbols-outlined"><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#ffffff"><path d="M440-160v-487L216-423l-56-57 320-320 320 320-56 57-224-224v487h-80Z"/></svg></span>',
        },
    }

    const onFilterTextBoxChanged = useCallback(() => {
        gridRef.current.api.setQuickFilter(
            document.getElementById('filter-text-box').value
        );
    }, []);
    return <>
        {
            ticketingConfiguration !== undefined && ticketingConfiguration.length > 0 ?
                <div className='vendor-listing' style={{ padding: "24px", paddingTop: " 0px", paddingLeft: " 24px", paddingRight: "24px" }}>
                    <TableContainer component={Paper} className="table-paper-styles">
                        <Table className="table-background">
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={6} className="py-2">
                                        <OutlinedInput
                                            id="filter-text-box"
                                            onInput={onFilterTextBoxChanged}
                                            placeholder="Keyword Search by Pseudo Code,Cabin Calss,Ticketing Type,Fare Type,Queue Format,Queue Number,Void Number,Void Format"
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton>
                                                        <SearchOutlinedIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <div className="ag-theme-material">
                                    <AgGridReact
                                        id='ticketing-configuration-grid'
                                        columnDefs={columnDefs}
                                        rowData={constructedRowData(ticketingConfiguration)}
                                        defaultColDef={defaultColDef}
                                        gridOptions={gridOptions}
                                        onGridReady={onGridReady}
                                        pagination={ticketingConfiguration && ticketingConfiguration.length > RowsPerPage}
                                        paginationPageSize={10}
                                        rowHeight={rowHeight}
                                        ref={gridRef}
                                        cacheQuickFilter={true}
                                    />
                                </div>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div> : ""
        }
    </>
}
