import moment from "moment";
import React, { useEffect, useRef } from 'react';
import findAirlinebyCode from "../../functions/findAirlineByCode";
import getDuration from "../../functions/getDuration";
import getAirportByCode from "../../functions/getAirportByCode";
import BootstrapTooltip1 from "../BootstrapToolTip/BootstrapToolTip1";
import { Grid, Table } from "@mui/joy";
import { useTranslation } from "react-i18next";
export default function FlightSegmentDetails({ flight, setMaxHeight, bookingDetails }) {
    const { t } = useTranslation();
    const tableBodyRef = useRef(null);
    useEffect(() => {
        if (tableBodyRef.current) {
            const columns = tableBodyRef.current.children;
            let height = 0;

            // Find the tallest column
            for (const column of columns) {
                if (column.clientHeight > height) {
                    height = column.clientHeight;
                }
            }
            // Update the maxHeight in the parent component
            setMaxHeight((maxHeight) => height);
        }
    }, [flight]);
    return (
        <>

            {flight.segGroups && flight.segGroups.length > 0 ? (


                flight.segGroups.map((segGroup) => (
                    <div className="flight-itenary">
                        <div className="flight-itenary-header">
                            <div className="flight-itenary-title-container">
                                <div>
                                    {t("Itinerary Details")}
                                </div>

                            </div>

                        </div>
                        {segGroup.segments && segGroup.segments.length > 0 ? (
                            <>
                                <Table aria-label="basic table" id="seggroupTable">
                                    <thead>
                                        <tr >
                                            <BootstrapTooltip1 title={t("Airline/Flight No/ Coach")}>
                                                <th className="flightItineraryColumnHeader">{t("Airline/Flight No/ Coach")}</th>
                                            </BootstrapTooltip1>
                                            <BootstrapTooltip1 title={t("Departure Airport / Terminal")}>
                                                <th className="flightItineraryColumnHeader">{t("Departure Airport / Terminal")}</th>
                                            </BootstrapTooltip1>
                                            <BootstrapTooltip1 title={t("Departure Date & Time")}>
                                                <th className="flightItineraryColumnHeader">{t("Departure Date & Time")}</th>
                                            </BootstrapTooltip1>
                                            <BootstrapTooltip1 title={t("Arrival Airport / Terminal")}>
                                                <th className="flightItineraryColumnHeader">{t("Arrival Airport / Terminal")}</th>
                                            </BootstrapTooltip1>
                                            <BootstrapTooltip1 title={t("Arrival Date & Time")}>
                                                <th className="flightItineraryColumnHeader">{t("Arrival Date & Time")}</th>
                                            </BootstrapTooltip1>
                                            <BootstrapTooltip1 title={t("Duration")}>
                                                <th className="flightItineraryColumnHeader" style={{ width: '5vw', maxWidth: '5vw' }}>{t("Duration")}</th>
                                            </BootstrapTooltip1>
                                            {/*<BootstrapTooltip1 title={''}>
                                              <th style={{textAlign:'center'}}>FBA</th> 
                                            </BootstrapTooltip1>*/}
                                            <BootstrapTooltip1 title={t("Airline PNR")}>
                                                <th className="flightItineraryColumnHeader" style={{ width: '5vw', maxWidth: '5vw' }}>{t("Airline PNR")}</th>
                                            </BootstrapTooltip1>
                                        </tr>
                                    </thead>
                                    <tbody ref={tableBodyRef}>
                                        {
                                            segGroup.segments.map((segment, index) => (
                                                <tr >
                                                    <td className="flightItineraryColumn" key={`${index}`}>{findAirlinebyCode(segment.mrkAirline)}  <br />  <b>{segment.flightNum}</b>  /  {segment.cabinClass}</td>
                                                    <td className="flightItineraryColumn">{getAirportByCode(segment.origin)} <br /> <b>{segment.origin}</b>  / {segment.depTerminal}</td>
                                                    <td className="flightItineraryColumn">{moment(segment.departureOn).format("Do MMM  YYYY")}<br />
                                                        {moment(segment.departureOn).format("hh:mm A")}</td>
                                                    <td className="flightItineraryColumn">{getAirportByCode(segment.destination)}<br /> <b>{segment.destination}</b>  / {segment.arrTerminal}</td>
                                                    <td className="flightItineraryColumn">{moment(segment.arrivalOn).format("Do MMM  YYYY")} <br />
                                                        {moment(segment.arrivalOn).format("hh:mm A")}</td>
                                                    <td className="flightItineraryColumn" style={{ width: '5vw', maxWidth: '5vw' }}>{getDuration(segment.duration)}</td>
                                                    {/* <td></td> */}
                                                    <td className="flightItineraryColumn" style={{ width: '5vw', maxWidth: '5vw' }}>{segment.pnr ? segment.pnr : "-"}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            </>
                        ) : (
                            <div></div>
                        )}
                    </div>
                ))
            ) : (
                <div></div>
            )}
        </>
    );
}